<template>
  <div
    :id="slug"
    class="sb-fullwidth-banner"
    data-test-id="pro-banner"
    :style="`${backgroundColor(bg_color)}; --text-color: ${text_color || 'inherit'}`"
    :class="{ 'img-no-padding': img_no_padding }"
  >
    <div class="inner-container fad">
      <div class="sb-fullwidth-banner-flex" :class="{ rtl }">
        <div class="sb-fullwidth-banner-txt">
          <div v-if="richTagline" class="sb-fullwidth-banner-tagline" v-html="richTagline" />
          <div
            v-if="richTitle"
            class="sb-fullwidth-banner-title"
            data-test-id="home-pro-banner-title"
            v-html="richTitle"
          />
          <div v-if="richSubtitle" class="sb-fullwidth-banner-subtitle" v-html="richSubtitle" />
          <div v-if="richText" class="sb-fullwidth-banner-description" v-html="richText" />
          <div v-if="buttons?.length" class="sb-fullwidth-banner-btns">
            <SbCtaGroup :buttons="buttons" />
          </div>
        </div>
        <div v-if="image_url || image?.filename" class="sb-fullwidth-banner-img">
          <ImageLazy
            :src="image_url || image.filename"
            :alt="image?.alt || richTitle || $t('brandName')"
            :title="image?.title || richTitle || $t('brandName')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SbCtaGroup from '@/components/Storyblok/SbCtaGroup'
import Mobile from '@/mixins/mobile-mixin'

export default {
  name: 'SbFullwidthBanner',
  components: {
    SbCtaGroup,
  },
  mixins: [Mobile],
  props: {
    slug: { type: String, default: undefined },
    tagline: { type: [Object, String], default: undefined },
    title: { type: [Object, String], default: undefined },
    subtitle: { type: [Object, String], default: undefined },
    text: { type: [Object, String], default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    image_url: { type: String, default: null },
    image: { type: Object, default: () => {} },
    rtl: { type: Boolean, default: false },
    buttons: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    text_color: { type: String, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    img_no_padding: { type: Boolean, default: false },
  },
  computed: {
    richTagline() {
      if (renderRichText(this.tagline) === '<p></p>') return false
      return renderRichText(this.tagline)
    },
    richTitle() {
      if (renderRichText(this.title) === '<p></p>') return false
      return renderRichText(this.title)
    },
    richSubtitle() {
      if (renderRichText(this.subtitle) === '<p></p>') return false
      return renderRichText(this.subtitle)
    },
    richText() {
      return renderRichText(this.text)
    },
    cls() {
      return this.$parent.cls
    },
  },
  methods: {
    backgroundColor(color = null) {
      return color ? `background-color: ${color}` : ''
    },
  },
}
</script>

<style lang="scss">
.sb-fullwidth-banner {
  padding: 0 $spacing-xl $spacing-xl $spacing-xl;
  border-radius: $spacing-xs;

  @include mq($mq-md) {
    .inner-container.fad {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include mq($mq-xs) {
    padding: 0 $spacing-md $spacing-md $spacing-md;
  }

  p {
    color: var(--text-color);
  }

  &-title,
  &-description,
  &-subtitle {
    margin-bottom: $spacing-sm;

    h2 {
      line-height: 1.3;
    }

    p,
    b,
    a {
      color: var(--text-color);
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    b {
      font-weight: 800;
    }

    &-title,
    &-description,
    &-subtitle {
      margin-bottom: $spacing-sm;

      h2 {
        line-height: 1.3;
      }
    }
    &-description {
      padding-top: $spacing-sm;
    }
    &-tagline,
    &-subtitle,
    &-title {
      img {
        width: 38px;
        height: 38px;
      }
    }
    &-tagline > :first-child,
    &-subtitle > :first-child {
      align-items: center;
      display: flex;
      gap: $spacing-sm;
      padding-bottom: $spacing-sm;
    }
  }
  &-tagline,
  &-subtitle,
  &-title {
    img {
      width: 38px;
      height: 38px;
    }
  }
  &-tagline > :first-child,
  &-subtitle > :first-child {
    align-items: center;
    display: flex;
    gap: $spacing-sm;
    font-weight: 800;
  }
  &-btns {
    display: flex;
    gap: $spacing-md;
  }
  &-btn {
    flex: 1;
  }
  &-txt {
    width: 50%;
    @include mq($mq-md) {
      width: 60%;
    }
    @include mq($mq-sm) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        text-align: center;
      }
    }
  }

  &-flex {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: $spacing-lg 0 $spacing-sm 0;
    gap: $spacing-sm;
    @include mq($mq-sm) {
      flex-direction: column-reverse;
      align-items: normal;
      padding: $spacing-lg $spacing-md;
      gap: 0;
    }

    .sb-cta-groups-wrapper {
      @include mq($mq-xs) {
        padding: 0;
      }
    }

    // & > * {
    //   @include mqmin($mq-sm) {
    //     flex: 0 0 calc(50% - 20px / 2);
    //   }
    // }
  }

  &-img {
    @include mq($mq-sm) {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
  .rtl {
    flex-direction: row-reverse;
    @include mq($mq-sm) {
      flex-direction: column-reverse;
    }
  }

  &.img-no-padding {
    padding-bottom: 0 !important;
    .sb-fullwidth-banner-flex {
      padding-bottom: 0 !important;
    }
    .sb-fullwidth-banner-img {
      margin-bottom: 0 !important;
    }
    @include mq($mq-sm) {
      .rtl {
        flex-direction: column;
      }
    }
  }
  ul {
    list-style: disc;
    li {
      margin-left: $spacing-md;
    }
  }
}
</style>
