import { watch, onMounted } from 'vue'
import { useClubStore } from '@/stores/club'

export function useTheme() {
  const clubStore = useClubStore()
  const setTheme = config => {
    const theme = config?.theme
    if (theme && Object.keys(theme).length) {
      Object.keys(theme.colors).forEach(color => {
        document.querySelector('html').style.setProperty(`--${color}`, theme.colors[color])
      })
    }
  }

  // Watch the clubConfig and update the theme when it changes
  watch(
    () => clubStore.clubConfig,
    newVal => {
      setTheme(newVal)
    },
    { deep: true }
  )

  // Set the theme on initial mount
  onMounted(() => {
    setTheme(clubStore.clubConfig.value)
  })

  return {
    setTheme,
  }
}
