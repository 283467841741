import { defineNuxtPlugin } from '#app/nuxt'
import { LazySbAdditionalServicesBanner, LazySbAdditionalServicesPage, LazySbAdditionalServicesSection, LazySbAgecSection, LazySbAvatar, LazySbAvatarList, LazySbBlogAd, LazySbButtonsLinkHover, LazySbContactForm, LazySbContainerBanner, LazySbContent, LazySbCta, LazySbCtaBanner, LazySbCtaGroup, LazySbDemoBanner, LazySbDownloadBanner, LazySbFeatureBlock, LazySbFeaturesSection, LazySbFullwidthBanner, LazySbNetworkSection, LazySbNewsCard, LazySbOfferDetail, LazySbOffersSection, LazySbOffersSelectionGrid, LazySbPlan, LazySbPlansGroup, LazySbPricingPlan, LazySbProContent, LazySbRichText, LazySbSeoContent, LazySbServicesResumeSection, LazySbServicesSection, LazySbSubscribeModal, LazySbSubtitle, LazySbTable, LazySbTestimoniesTabs, LazySbTitle, LazySbVideoSection, LazySbWhiteBookForm } from '#components'
const lazyGlobalComponents = [
  ["SbAdditionalServicesBanner", LazySbAdditionalServicesBanner],
["SbAdditionalServicesPage", LazySbAdditionalServicesPage],
["SbAdditionalServicesSection", LazySbAdditionalServicesSection],
["SbAgecSection", LazySbAgecSection],
["SbAvatar", LazySbAvatar],
["SbAvatarList", LazySbAvatarList],
["SbBlogAd", LazySbBlogAd],
["SbButtonsLinkHover", LazySbButtonsLinkHover],
["SbContactForm", LazySbContactForm],
["SbContainerBanner", LazySbContainerBanner],
["SbContent", LazySbContent],
["SbCta", LazySbCta],
["SbCtaBanner", LazySbCtaBanner],
["SbCtaGroup", LazySbCtaGroup],
["SbDemoBanner", LazySbDemoBanner],
["SbDownloadBanner", LazySbDownloadBanner],
["SbFeatureBlock", LazySbFeatureBlock],
["SbFeaturesSection", LazySbFeaturesSection],
["SbFullwidthBanner", LazySbFullwidthBanner],
["SbNetworkSection", LazySbNetworkSection],
["SbNewsCard", LazySbNewsCard],
["SbOfferDetail", LazySbOfferDetail],
["SbOffersSection", LazySbOffersSection],
["SbOffersSelectionGrid", LazySbOffersSelectionGrid],
["SbPlan", LazySbPlan],
["SbPlansGroup", LazySbPlansGroup],
["SbPricingPlan", LazySbPricingPlan],
["SbProContent", LazySbProContent],
["SbRichText", LazySbRichText],
["SbSeoContent", LazySbSeoContent],
["SbServicesResumeSection", LazySbServicesResumeSection],
["SbServicesSection", LazySbServicesSection],
["SbSubscribeModal", LazySbSubscribeModal],
["SbSubtitle", LazySbSubtitle],
["SbTable", LazySbTable],
["SbTestimoniesTabs", LazySbTestimoniesTabs],
["SbTitle", LazySbTitle],
["SbVideoSection", LazySbVideoSection],
["SbWhiteBookForm", LazySbWhiteBookForm],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
