export default defineNuxtPlugin(ctx => {
  const clickOutside = {
    mounted(el, binding) {
      if (import.meta.client) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event)
          }
        }
        document.addEventListener('click', el.clickOutsideEvent)
      }
    },
    unmounted(el) {
      if (import.meta.client) {
        document.removeEventListener('click', el.clickOutsideEvent)
      }
    },
    ssrRender(el, binding) {
      return {
        id: binding.value,
      }
    },
    getSSRProps(binding) {
      return {
        id: binding.value,
      }
    },
  }
  if (import.meta.client) {
    ctx.vueApp.directive('click-outside', clickOutside)
  }
})
