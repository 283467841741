import Repository from './repository'

export default class StorageRepository extends Repository {
  create(companyUid, storage, mapping = this.mapper.basicMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/storages`
    let config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, { storage }, config).then(response => {
      return response.storage
    })
  }

  async getStorages(filters = [], mapping = this.mapper.basicMapping) {
    const url = `${this.backendUrl}storages`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    if (filters && filters.length > 0) {
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params.product_query = keywordsFilter.value
    }
    return this.fetcher.get(url, config).then(response => response.storages)
  }

  async getStorageBySlug(slug, mapping = this.mapper.basicMapping) {
    const url = `${this.backendUrl}storages/${slug}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.storage)
  }

  async getCompanyStorages(uid, mapping = this.mapper.basicMapping) {
    const url = `${this.backendUrl}companies/${uid}/storages`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.storages)
  }
}
