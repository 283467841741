<template>
  <div :id="tag" class="ld-contact-section" :style="`--contact-form-bg-color:${background_color}`">
    <div class="inner-container fad">
      <div class="ld-contact-section__flex">
        <SbContent v-if="blocks?.length" :content="{ body: blocks }" />
        <div class="ld-contact-section__form-card">
          <template v-if="!success">
            <p class="h3 ld-contact-section__form-card__title">
              {{ title || $t('actions.knowMore') }}
            </p>
            <p class="landing-download__subtitle ld-contact-section__form-card__subtitle">
              {{ text || $t('message.askToBeContacted') }}
            </p>
            <form class="ld-contact-section__form-card__form" @submit.prevent="contact">
              <InputField
                v-model="prospect.last_name"
                input-type="text"
                :field-title="$t('fields.lname')"
                :input-rules="['required']"
                :placeholder-value="$t('fields.lname')"
              >
                <template #icon>
                  <div class="icon-user u-bg-steel-grey" />
                </template>
              </InputField>
              <InputField
                v-model="prospect.first_name"
                input-type="text"
                :field-title="$t('fields.fname')"
                :input-rules="['required']"
                :placeholder-value="$t('fields.fname')"
              >
                <template #icon>
                  <div class="icon-user u-bg-steel-grey" />
                </template>
              </InputField>
              <InputPhone
                id="phone"
                ref="phone"
                v-model="phoneNumber"
                class="u-p-b-sm"
                @is-phone-invalid="isPhoneInvalid = $event"
              />
              <InputField
                v-model="prospect.email"
                input-type="email"
                :field-title="$t('fields.email')"
                :input-rules="['required']"
                :placeholder-value="$t('fields.email')"
              >
                <template #icon>
                  <div class="icon-email u-bg-steel-grey" />
                </template>
              </InputField>

              <InputField
                v-if="with_company"
                v-model="prospect.name"
                input-type="text"
                :field-title="$t('fields.companyName')"
                :placeholder-value="$t('fields.companyName')"
              >
                <template #-slot:icon>
                  <div class="icon-company u-bg-steel-grey" />
                </template>
              </InputField>

              <OriginForm
                v-if="!with_company && tld === 'FR'"
                ref="origin"
                v-model="prospect.origin_uid"
                :icon-left="infoIcon"
                :input-rules="!with_company ? ['required'] : []"
                class="ld-contact-section__form-card__form-origin"
              />

              <div v-if="isLoading" class="loader" />
              <p v-if="error" class="error">
                {{ error }}
              </p>
              <MainButton
                v-else
                :disabled="isFormInvalid"
                :label="$t('actions.beContacted')"
                tag="button"
                class-variant="btn-blue full-width"
                class="ld-contact-section__form-card__btn"
              />
            </form>
          </template>
          <template v-else>
            <p class="h3 ld-contact-section__form-card__title">
              {{ $t('message.ourTeamWillDo') }}
            </p>
            <div v-if="successImage?.filename" class="ld-contact-section__form-card__img">
              <ImageLazy :src="successImage.filename" :alt="successImage.alt" :title="successImage.title" />
            </div>
            <p
              class="landing-download__subtitle ld-contact-section__form-card__subtitle"
              v-html="$t('message.askedContactSuccess')"
            />
          </template>
        </div>
        <div v-if="!blocks?.length && image?.filename" class="ld-contact-section__img">
          <ImageLazy :src="image.filename" :alt="image.alt" :title="image.title" width="480" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import API from '@/mixins/sp-api-mixin'
import i18nMixin from '@/mixins/i18n-mixin'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputField from '@/components/InputField/InputField'
import OriginForm from '@/components/Form/OriginForm/OriginForm'
import InputPhone from '@/components/InputField/InputPhone'

import InfoIcon from '@/assets/icons/ico-info.svg'

export default {
  name: 'SbContactForm',
  components: {
    MainButton,
    InputField,
    OriginForm,
    SbContent: () => '@/components/Storyblok/SbContent',
    InputPhone,
  },
  mixins: [API, i18nMixin],
  props: {
    title: { type: String, default: null },
    text: { type: String, default: null },
    tag: { type: String, required: true },
    image: { type: Object, default: () => {} },
    successImage: { type: Object, default: () => {} },
    // eslint-disable-next-line vue/prop-name-casing
    success_image: { type: Object, default: () => {} },
    // eslint-disable-next-line vue/prop-name-casing
    with_company: { type: Boolean, default: false },
    blocks: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/prop-name-casing
    background_color: { type: String, default: '' },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isFormInvalid: true,
      isPhoneInvalid: true,
      infoIcon: InfoIcon,
      success: false,
      phoneNumber: null,
      prospect: {
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        tags: [this.tag],
        is_person: true,
        origin_uid: null,
      },
    }
  },
  computed: {
    tags() {
      // Taken from Legacy code
      const utms = [].concat(this.$store.state.utm.list)
      utms.push(this.tag)
      return utms.join(',')
    },
  },
  watch: {
    prospect: {
      handler() {
        this.checkFormValidity()
      },
      deep: true,
    },
    isPhoneInvalid: {
      handler() {
        this.checkFormValidity()
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const { $currentUser } = useNuxtApp()
    this.prospect = {
      first_name: $currentUser()?.name || null,
      last_name: $currentUser()?.lastName || null,
      phone_number: $currentUser()?.phone ? { phone_number: $currentUser().phone } : null,
      email: $currentUser()?.email || null,
      tags: [this.tag],
      name: $currentUser()?.company?.name || null,
      is_person: true,
      origin_uid: null,
    }
    this.phoneNumber = $currentUser()?.phone ? { phone_number: $currentUser().phone } : null
  },
  methods: {
    checkFormValidity() {
      for (const item of Object.entries(this.$refs)) {
        this.isFormInvalid = item[1].v$ && item[1].v$.$invalid
        if (this.isFormInvalid) {
          console.log('isFormInvalid', item)
          break
        }
      }
      this.isFormInvalid = this.isFormInvalid || this.isPhoneInvalid
    },

    contact() {
      if (!this.isFormInvalid) {
        this.isLoading = true
        this.prospect.tags = this.tags
        if (!Array.isArray(this.prospect.tags)) {
          this.prospect.tags = [this.prospect.tags]
        }
        const payload = { ...this.prospect, phone_number: this.phoneNumber }
        const { $api } = useNuxtApp()
        this.spRequest({
          req: $api().prospect.subscribeProspect(payload),
        })
          .then(() => {
            this.success = true
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
.ld-contact-section {
  margin: 60px 0;

  &__flex {
    background-color: var(--contact-form-bg-color);
    border-radius: $spacing-md;
    padding: $spacing-lg;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($mq-sm) {
      flex-direction: column-reverse;
    }
  }

  &__form-card {
    flex: 0 0 calc(33.33% - 20px / 2);
    background: var(--white);
    box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15); // TODO: define a variable
    border-radius: 20px; // TODO: define a variable
    padding: 20px;

    @include mq($mq-md) {
      flex: 0 0 calc(50% - 20px / 2);
    }

    @include mq($mq-sm) {
      width: 100%;
    }

    &__form-origin {
      margin-top: 43px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__img {
      margin-bottom: 20px;

      img {
        display: block;
        max-width: 150px;
      }
    }

    &__subtitle {
      color: var(--steel-grey);
    }

    &__btn {
      margin-top: 20px;
    }
  }

  &__img {
    flex: 0 0 calc(66.66% - 20px / 2);

    @include mq($mq-md) {
      flex: 0 0 calc(50% - 20px / 2);
    }

    @include mq($mq-sm) {
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
</style>
