import Repository from './repository'

export default class OrderRepository extends Repository {
  async getPurchases(companyUid, userUid, params, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/orders`
    const config = {
      params,
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      results: response.orders,
      total: parseInt(response.total, 10),
    }))
  }

  async getSales(companyUid, params, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders`
    const config = {
      params,
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      results: response.orders,
      total: parseInt(response.total, 10),
    }))
  }

  async getPurchase(companyUid, userUid, orderUid, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/orders/${orderUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.order)
  }

  async getSale(companyUid, orderUid, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.order)
  }

  async getOrderStock(companyUid, orderUid, mapping = this.mapper.frozenOrderMapping) {
    const url = `${this.backendUrl}orders/${orderUid}/order_stock`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.order_stocks)
  }

  async approveOrder(companyUid, orderUid, approved, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { approved }, config).then(response => response.order)
  }

  async updateSaleOrder(companyUid, order, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${order.uid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { order }, config).then(response => response.order)
  }

  async updatePurchaseOrder(companyUid, userUid, order, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/orders/${order.uid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { order }, config).then(response => response.order)
  }

  async checkOrderDeliveryCode(companyUid, orderUid, code, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}/check`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, { delivery_code: code }, config).then(response => response.order)
  }

  async checkShipping(companyUid, orderUid, shipping, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}/check`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, shipping, config).then(response => response.order)
  }

  async addDocument(companyUid, orderUid, document, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}/documents`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, { document }, config).then(response => response.document.order)
  }

  async deleteDocument(companyUid, orderUid, documentUid, mapping = this.mapper.orderMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}/documents/${documentUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.delete(url, config).then(response => response.order)
  }

  async reportIssues(companyUid, orderUid, issues) {
    const url = `${this.backendUrl}companies/${companyUid}/orders/${orderUid}/issues`
    return this.fetcher.post(url, issues).then(response => response.order)
  }
}
