import qs from 'qs'

import { useClubStore } from '@/stores/club'
import { useI18nStore } from '@/stores/i18nStore'

import ReferenceRepository from '@/repositories/referenceRepository'
import BrandRepository from '@/repositories/brandRepository'
import CategoryRepository from '@/repositories/categoryRepository'
import ProspectRepository from '@/repositories/prospectRepository'
import LocationRepository from '@/repositories/locationRepository'
import CompanyRepository from '@/repositories/companyRepository'
import SpecialtyRepository from '@/repositories/specialtyRepository'
import PromoCodeRepository from '@/repositories/promoCodeRepository'
import MetaRepository from '@/repositories/metaRepository'
import UserRepository from '@/repositories/userRepository'
import CartRepository from '@/repositories/cartRepository'
import OrderRepository from '@/repositories/orderRepository'
import StockRepository from '@/repositories/stockRepository'
import MerchantRepository from '@/repositories/merchantRepository'
import CarrierRepository from '@/repositories/carrierRepository'
import AutocompleteRepository from '@/repositories/autocompleteRepository'
import MkpAlertRepository from '@/repositories/mkpAlertRepository'
import StorageRepository from '@/repositories/storageRepository'
import DashboardRepository from '@/repositories/dashboardRepository'
import GeoRepository from '@/repositories/geoRepository'
import ClubRepository from '@/repositories/clubRepository'
import QuoteRepository from '@/repositories/quoteRepository'
import AppPurchaseRepository from '@/repositories/appPurchaseRepository'
import TagsRepository from '@/repositories/tagsRepository'
import DownloadFilesRepository from '@/repositories/downloadFilesRepository'
import StoryblokRepository from '@/repositories/storyblokRepository'

import UserMapper from '@/helpers/mappers/UserMapperHelper'
import CompanyMapper from '@/helpers/mappers/CompanyMapperHelper'
import OrderMapper from '@/helpers/mappers/OrderMapperHelper'
import ReferenceMapper from '@/helpers/mappers/ReferenceMapperHelper'
import StockMapper from '@/helpers/mappers/StockMapperHelper'
import BrandMapper from '@/helpers/mappers/BrandMapperHelper'
import CategoryMapper from '@/helpers/mappers/CategoryMapperHelper'
import CartMapper from '@/helpers/mappers/CartMapperHelper'
import MkpAlertMapper from '@/helpers/mappers/MkpAlertMapperHelper'
import StorageMapper from '@/helpers/mappers/StorageMapperHelper'
import ClubMapper from '@/helpers/mappers/ClubMapperHelper'
import QuoteMapper from '@/helpers/mappers/QuoteMapperHelper'
// import { LRUCache } from 'lru-cache'

// const options = {
//   max: 500,
//   // for use with tracking overall storage size
//   maxSize: 5000,
//   sizeCalculation: (/*value, key*/) => {
//     return 1
//   },
//   // for use when you need to clean up something when objects
//   // are evicted from the cache
//   dispose: (/*value, key*/) => {
//     // freeFromMemoryOrWhatever(value)
//   },
//   // how long to live in ms
//   ttl: 1000 * 60 * 1,
//   // return stale items before removing from cache?
//   allowStale: false,
//   updateAgeOnGet: true,
//   updateAgeOnHas: false,
//   // async method to use for cache.fetch(), for
//   // stale-while-revalidate type of behavior
//   fetchMethod: async (/*key, staleValue, { options, signal, context }*/) => {},
// }

// const cache = new LRUCache(options)

// TODO refacto https://medium.com/@luizzappa/nuxt-3-repository-pattern-organising-and-managing-your-calls-to-apis-with-typescript-acd563a4e046
export default class ApiService {
  constructor({ backendUrl, storyApi, runtimeConfig }) {
    this.backendUrl = backendUrl
    this.backendUrlV2 = backendUrl.replace('/api/v1', '/api/v2')
    this.runtimeConfig = runtimeConfig

    this.reference = new ReferenceRepository({
      runtimeConfig,
      fetcher: this.fetch(),
      backendUrl,
      mapper: ReferenceMapper,
    })
    this.brand = new BrandRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: BrandMapper })
    this.category = new CategoryRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: CategoryMapper })
    this.prospect = new ProspectRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.location = new LocationRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.user = new UserRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: UserMapper })
    this.company = new CompanyRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: CompanyMapper })
    this.specialty = new SpecialtyRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.meta = new MetaRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.promoCode = new PromoCodeRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.cart = new CartRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: CartMapper })
    this.order = new OrderRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: OrderMapper })
    this.stock = new StockRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: StockMapper })
    this.merchant = new MerchantRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: CompanyMapper })
    this.carrier = new CarrierRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.autocomplete = new AutocompleteRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.mkpAlert = new MkpAlertRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: MkpAlertMapper })
    this.storage = new StorageRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: StorageMapper })
    this.dashboard = new DashboardRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.geo = new GeoRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.club = new ClubRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: ClubMapper })
    this.quote = new QuoteRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, mapper: QuoteMapper })
    this.appPurchase = new AppPurchaseRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.tags = new TagsRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.downloadFiles = new DownloadFilesRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl })
    this.storyblok = new StoryblokRepository({ runtimeConfig, fetcher: this.fetch(), backendUrl, storyApi })
  }

  async fetchData({ method, url, options = {}, body = null }) {
    const controller = new AbortController()
    let queryParams = ''
    let mainError = null
    const { token, status } = useAuthState()

    const headers = {
      ...options.headers,
    }
    if (status.value === 'authenticated') {
      headers['Authorization'] = token?.value || token
    }
    headers['Mkp-web'] = true
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json'
      headers['Accept'] = 'application/json, text/plain, */*'
    }
    const i18nStore = useI18nStore()
    headers['X-Country'] = i18nStore.tld?.toLowerCase()
    headers['X-Language'] = i18nStore.locale
    const clubStore = useClubStore()
    if (clubStore.isClub) {
      const companyGroupHeader = clubStore.domain
      // console.log('set header for club ' + companyGroupHeader)
      headers['Company-Group'] = companyGroupHeader
      headers['X-Company-Group'] = companyGroupHeader
    }

    if (options.params) {
      const queryString = qs.stringify(options.params, { encode: false, arrayFormat: 'brackets' })
      queryParams = queryString ? `?${queryString}` : ''
      delete options.params
    }
    // const nuxtApp = useNuxtApp()
    // const { country, locale } = useCurrentLocale()
    // const uniqueKey = `${country.code}:${locale}:${method}:${url}${queryParams}` // Unique key for caching based on URL and query params
    // console.log('uniqueKey', uniqueKey, cache.has(uniqueKey))
    // // Check the cache before making a request
    // if (method === 'get' && cache.has(uniqueKey)) {
    //   return cache.get(uniqueKey)
    // }

    const fetchParams = {
      method,
      // baseURL: this.backendUrl,
      ...options,
      // redirect: 'manual',
      signal: controller.signal,
      body: body ? JSON.stringify(body) : null,
      // credentials: 'omit',
      headers,
      // key: uniqueKey,
      // retry: 2,
      // retryDelay: 500,
      onRequestError({ error }) {
        // console.log('onRequestError', error)
        mainError = error
      },
      onResponse({ request, response }) {
        // console.log('onResponse', response)
        const total = response.headers.get('total')
        if (total) {
          response._data.total = total
        }
        if (request.includes('sign_in')) {
          const authorization = response.headers.get('authorization')
          response._data.authorization = authorization
        }
      },
      onResponseError({ response }) {
        // console.log('onResponseError', response)
        mainError = response._data
      },
    }
    if (method === 'delete' && !fetchParams.body) {
      // hack found here : https://github.com/unjs/h3/issues/375#issuecomment-1907976548
      fetchParams.body = []
    }
    if (import.meta.client) {
      await nextTick()
    }
    // console.log('url', url)
    try {
      let fetchFunction = useFetch
      let response = await fetchFunction(`${url}${queryParams}`, fetchParams)
      if (response.error?.value) {
        throw new Error(response.error.value || 'Error occurred')
      } else if (mainError) {
        throw new Error(mainError)
      }
      if (!response.data.value) {
        // The data was not cached, so fetch it from the server
        await response.refresh()
      } else {
        // list every endpoint that not have to be cached in v1 and v2 api version
        // Be careful with sensitive data, even if a url with a userId is stored with the user id and therefore cannot be sent to another user
        // const uncachedUrls = [
        //   `${this.backendUrl}cart`,
        //   `${this.backendUrl}carts`,
        //   `${this.backendUrl}orders`,
        //   `${this.backendUrl}quotes`,
        //   `${this.backendUrl}companies`,
        //   `${this.backendUrl}me`,
        //   `${this.backendUrl}company_groups`,
        //   `${this.backendUrl}customer_dashboard`,
        //   `${this.backendUrlV2}cart`,
        //   `${this.backendUrlV2}carts`,
        //   `${this.backendUrlV2}companies`,
        // ]
        // if (method === 'get' && !uncachedUrls.includes(url)) {
        //   cache.set(uniqueKey, response.data?.value || response.data || response)
        // }
        return response.data?.value || response.data || response
      }
      // }
    } catch (error) {
      console.log('error useFetch trycatch', mainError, error)
      throw { response: { data: mainError || error } }
    }
  }

  fetch() {
    return {
      get: (url, options = {}) => {
        return this.fetchData({ method: 'get', url, options })
      },
      put: (url, body, options = {}) => {
        return this.fetchData({ method: 'put', url, options, body })
      },
      patch: (url, body, options = {}) => {
        return this.fetchData({ method: 'put', url, options, body })
      },
      post: (url, body, options = {}) => {
        return this.fetchData({ method: 'post', url, options, body })
      },
      delete: (url, options = {}, body = null) => {
        return this.fetchData({ method: 'delete', url, options, body })
      },
      customFetch: (params = {}) => {
        if (!params.url || !params.method) return Promise.reject(new Error('url and method are required'))
        const fetchParams = { method: params.method, url: params.url, options: params }
        if (params.data) {
          fetchParams.body = params.data
        }
        return this.fetchData(fetchParams)
      },
    }
  }
}
