import {
  activationVuexStore,
  brandsVuexStore,
  categoriesVuexStore,
  filtersVuexStore,
  indexVuexStore,
  landingDownloadVuexStore,
  locationVuexStore,
  mobileVuexStore,
  proAreaVuexStore,
  productListVuexStore,
  referencesVuexStore,
  registrationVuexStore,
  snackbarVuexStore,
  stocksVuexStore,
  storagesVuexStore,
  usersVuexStore,
  utmVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      activation: {
        ...activationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      brands: {
        ...brandsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      categories: {
        ...categoriesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      filters: {
        ...filtersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      landingDownload: {
        ...landingDownloadVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      location: {
        ...locationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      mobile: {
        ...mobileVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      proArea: {
        ...proAreaVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      productList: {
        ...productListVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      references: {
        ...referencesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      registration: {
        ...registrationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      snackbar: {
        ...snackbarVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      stocks: {
        ...stocksVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      storages: {
        ...storagesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      users: {
        ...usersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      utm: {
        ...utmVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore