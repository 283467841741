import Repository from './repository'

export default class UserRepository extends Repository {
  async getMe(mapping = this.mapper.userMapping) {
    const url = `${this.backendUrl}me`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.user)
  }
  async getUser(userUid, companyUid, mapping = this.mapper.userMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.user)
  }

  async login(emailOrPhone = {}, password, mapping = this.mapper.userMapping) {
    const url = `${this.backendUrl}sign_in`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    const payload = {
      user: {
        ...emailOrPhone,
        password,
      },
    }
    // console.log(payload)
    return this.fetcher
      .post(url, payload, config)
      .then(response => {
        if (response.success) {
          const token = response.authorization
          // const token = response.user?.token
          if (token !== null && token !== undefined) {
            return {
              token,
              user: response.user,
            }
          }
          return {
            confirmed: response.confirmed,
            message: response.message,
          }
        } else {
          throw new Error(response.message)
        }
      })
      .catch(e => {
        return e
      })
  }

  async forgotPassword(emailOrPhone = {}) {
    const url = `${this.backendUrl}forgot_password`

    return this.fetcher
      .post(url, {
        user: emailOrPhone,
      })
      .then(response => response.success)
  }

  async activate(emailOrPhone, code, reset = false, mapping = this.mapper.userMapping) {
    const activateUrl = `${this.backendUrl}sign_in`
    const codeKey = reset ? 'reset_password_token' : 'confirmation_token'
    let user = {}
    if (!emailOrPhone?.login) {
      user = { login: emailOrPhone }
    } else {
      user = emailOrPhone
    }
    user[codeKey] = code
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(activateUrl, { user }, config).then(response => {
      if (response.success) {
        const token = response.authorization
        if (token !== null && token !== undefined) {
          return {
            token,
            user: response.user,
          }
        }
        return response.success
      }
      if (response.message === 'token_expired') {
        throw new Error('code_expired')
      } else if (response.message === 'token_invalid') {
        throw new Error('code_invalid')
      } else {
        throw new Error(response.message)
      }
    })
  }

  async setPassword(user, password) {
    const setPasswordUrl = `${this.backendUrl}companies/${user.company.uid}/users/${user.uid}`
    return this.fetcher
      .put(setPasswordUrl, {
        user: {
          password,
        },
      })
      .then(response => response.success)
  }

  async googleConnexion(credentials) {
    const url = `${this.backendUrlV2}google_sign_in`

    return this.fetcher.post(url, credentials).then(response => response)
  }

  async facebookConnexion(credentials) {
    const url = `${this.backendUrlV2}facebook_sign_in`

    return this.fetcher.post(url, credentials).then(response => response)
  }

  async getAddresses(userUid) {
    const addressesUrl = `${this.backendUrl}users/${userUid}/addresses`
    return this.fetcher.get(addressesUrl).then(response => response.addresses)
  }

  async saveAddress(userUid, address) {
    let addressesUrl = `${this.backendUrl}users/${userUid}/addresses`
    let method = 'POST'
    if (address.uid !== null) {
      addressesUrl = `${addressesUrl}/${address.uid}`
      method = 'PUT'
    }
    const params = {
      method,
      url: addressesUrl,
      data: { address },
    }
    return this.fetcher.customFetch(params).then(response => response.address)
  }

  async update(userUid, companyUid, user) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}`
    return this.fetcher.put(url, user).then(response => response.user)
  }

  async getCompanyUsersWithBookedStock(companyUid, mapping = this.mapper.userWithBookedStockMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users?with_booked_stocks=true`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.users)
  }
}
