import Repository from './repository'

export default class Tags extends Repository {
  async getStocksTags() {
    const url = `${this.backendUrl}stocks-tags`
    return this.fetcher
      .get(url)
      .then(response => {
        return response.stocks_tags
      })
      .catch(e => {
        console.log(e)
        throw e
      })
  }
}
