export const state = () => ({
  show: false,
  color: '',
  message: '',
  timeout: 2000,
  canBeClosed: true,
  customTop: null,
})

export const getters = {
  show: state => state.show,
  color: state => state.color,
  message: state => state.message,
  timeout: state => state.timeout,
  canBeClosed: state => state.canBeClosed,
  customTop: state => state.customTop,
}

export const actions = {
  hide({ commit }) {
    commit('hide')
  },
  show({ commit }, param) {
    commit('show', param)
  },
}

export const mutations = {
  hide(state) {
    state.show = false
  },
  show(state, param) {
    state.color = param.color
    state.message = param.message
    state.timeout = param.timeout ? param.timeout : 2000
    state.show = true
    state.canBeClosed = param.canBeClosed
    state.customTop = param.customTop
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
