<template>
  <div v-if="privateButton && button_center" class="sb-button-center">
    <main-button
      :label="privateButton.title"
      :link="privateButton.url"
      :outlined-color="privateButton.primary_color"
      tag="a"
      :target="isUrlExternal(privateButton.url) ? '_blank' : null"
      icon-position="RIGHT"
      :class="{ buttonCenter: button_center }"
      :class-variant="[
        { 'btn-outlined': privateButton.outlined, 'full-width': buttonLength > 1 },
        'sb-cta',
        classVariant,
      ]"
      :style-variant="[accentColorText, primaryColorBg]"
    >
      <slot />
    </main-button>
  </div>
  <main-button
    v-else-if="!button_center && privateButton"
    :label="privateButton.title"
    :link="privateButton.url"
    :outlined-color="privateButton.primary_color"
    tag="a"
    :target="isUrlExternal(privateButton.url) ? '_blank' : null"
    icon-position="RIGHT"
    :class-variant="[
      { 'btn-outlined': privateButton.outlined, 'full-width': buttonLength > 1 },
      'sb-cta',
      classVariant,
    ]"
    :style-variant="[accentColorText, primaryColorBg]"
  >
    <slot />
  </main-button>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton/MainButton'
import URL from '@/mixins/url-mixin'
export default {
  name: 'SbCta',
  components: {
    MainButton,
  },
  mixins: [URL],
  props: {
    button: { type: Object, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    button_center: { type: Boolean, default: undefined },
    buttonLength: { type: Number, default: 1 },
    classVariant: { type: null, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    primary_color: { type: null, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    accent_color: { type: null, default: '' },
    outlined: { type: Boolean, default: false },
    title: { type: String, default: '' },
    url: { type: String, default: '' },
    icon: { type: Object, default: null },
  },
  computed: {
    primaryColorBg() {
      return { 'background-color': this.button?.primary_color || this.primary_color }
    },
    accentColorText() {
      return { color: this.button?.accent_color || this.accent_color }
    },
    privateButton() {
      return (
        this.button || {
          icon: this.icon,
          url: this.url,
          title: this.title,
          outlined: this.outlined,
          accent_color: this.accent_color,
          primary_color: this.primary_color,
        }
      )
    },
  },
}
</script>
<style lang="scss">
.btn.sb-cta {
  img {
    position: absolute;
    right: $spacing-md;
    height: 28px;
    width: 28px;
  }

  &:hover {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: var(--white) !important;

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.sb-cta.has-icon {
  @include mq($mq-xs) {
    padding: 6px $spacing-md !important;
  }
}
.sb-button-center {
  display: flex;
  justify-content: center;
}
</style>
