<template>
  <div v-if="plan" class="sb-plan">
    <div class="sb-plan-header">
      <div v-if="planService" class="sb-plan-title u-p-b-lg">
        <template v-if="planService">
          <SbButtonsLinkHover
            v-if="planService && planService.content"
            :link="{
              icon: planService.content.icon ? planService.content.icon.filename : null,
              label: planService.content.title,
              url: `/services/${planService.full_slug?.split('/').pop()}`,
              labelVisible: true,
              largeIcon: true,
            }"
          />
        </template>
      </div>
      <div v-else class="sb-plan-title u-p-b-lg" v-html="richTitleRender" />

      <div class="sb-plan-price">
        <div v-if="plan.price_info" class="sb-plan-price-starting-label">{{ $t('proArea.offers.startingLabel') }}</div>
        <span class="sb-plan-price-price u-p-b-sm">
          <span>{{ isNaN(selectedPlan.price) ? selectedPlan.price : localePrice(selectedPlan.price) }}</span>
          <span class="sb-solution-prices-container-solutions-item-price-price-tax">
            {{ plan.price_info }}
          </span>
        </span>
        <span class="sb-plan-price-info">
          <span>{{ plan.subtitle }}</span>
          <div v-if="plan.employees && plan.employees.length" class="sb-plan-price-salary">
            <div v-for="(employee, idx) in plansPerEmployee" :key="idx">
              <div
                v-if="employee"
                class="sb-plan-price-salary-item"
                :is-select="employee.employees === selectedPlan.employees"
                @click="selectPlan(employee)"
              >
                {{ employee.employees }}
              </div>
            </div>
            <div>
              <span>{{ $t('proArea.employees') }}</span>
            </div>
          </div>
        </span>
      </div>
    </div>

    <div v-if="plan.features && plan.features.content" class="sb-plan-list" v-html="features" />

    <div v-if="plan.button && plan.button.length" class="sb-plan-button">
      <SbCta :button="plan.button[0]" class-variant="full-width" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import API from '@/mixins/sp-api-mixin'
import Price from '@/mixins/price-mixin'

import SbCta from '@/components/Storyblok/SbCta'
import SbButtonsLinkHover from '@/components/Storyblok/SbButtonsLinkHover'

export default {
  name: 'SbPlan',
  components: {
    SbCta,
    SbButtonsLinkHover,
  },
  mixins: [API, Price],
  props: {
    plan: { type: Object, default: undefined },
  },
  emits: ['select-plan'],
  data() {
    return {
      selectedPlan: {
        ...this.plan,
        price: this.plan.default_price,
        employees: 0,
      },
      appPurchases: [],
    }
  },
  computed: {
    ...mapGetters('proArea', ['getStoryByUid']),
    richTitleRender() {
      if (this.plan.title) {
        return renderRichText(this.plan.title)
      }
      return null
    },
    features() {
      if (this.plan.features && import.meta.client) {
        const rendered = renderRichText(this.plan.features)
        const parser = new DOMParser()
        const doc = parser.parseFromString(rendered, 'text/html')
        const paragraphs = doc.querySelectorAll('p')
        const paragraphArray = Array.from(paragraphs).map(p => p.outerHTML)
        // Dynamic feature point when [tag in SB rich text Feature] : replace with selectedPlan special field
        const paragraphArrayDynamic = paragraphArray.map(item => {
          if (item.includes('[Dynamic users]')) {
            return renderRichText(this.selectedPlan.users)
          }
          return item
        })
        return paragraphArrayDynamic.join('')
      }
      return null
    },
    plansPerEmployee() {
      if (this.plan.employees.length) {
        return this.plan.employees
          .map(x => this.getStoryByUid(x)?.content)
          .map(x => {
            if (this.appPurchases && this.appPurchases.length) {
              return {
                ...x,
                ...(this.appPurchases.find(y => x.app_purchase_key === y.key) || {}),
              }
            }
            return x
          })
          .sort((a, b) => Number(a.employees) - Number(b.employees))
      }
      return []
    },
    planService() {
      return this.getStoryByUid(this.plan.service)
    },
  },
  watch: {
    plansPerEmployee: {
      handler(v) {
        if (v.length) {
          this.selectedPlan = v[0]
          this.$emit('select-plan', this.plansPerEmployee, this.selectedPlan)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.plan?.employees?.length) {
      // this.getAppPurchases()
    }
  },
  methods: {
    selectPlan(plan = this.selectedPlan) {
      this.selectedPlan = plan
      this.$emit('select-plan', this.plansPerEmployee, this.selectedPlan)
    },
    getAppPurchases() {
      const { $api } = useNuxtApp()
      this.spRequest({
        req: $api().appPurchase.get(),
      }).then(results => {
        this.appPurchases = results
      })
    },
  },
}
</script>

<style lang="scss">
.sb-plan {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 5px;
  padding: $spacing-md 0;
  width: 320px;
  min-width: 280px;

  &-header {
    height: 220px;
    border-bottom: 2px solid var(--light-grey);
  }

  &-title {
    padding: 0 $spacing-md;
  }

  &-title,
  &-title p {
    display: flex;
    align-items: center;
    gap: $spacing-sm;

    span {
      font-weight: 700;
      font-size: pxToRem(20px);
    }
  }

  &-price {
    display: flex;
    flex-direction: column;

    &-from {
      padding: 0 $spacing-md;
      font-weight: 400;
      font-size: pxToRem(12px);
    }

    &-price {
      color: var(--primary);
      font-weight: 700;
      font-size: pxToRem(30px);
      padding: 0 $spacing-md;

      &-tax {
        color: var(--light-grey);
      }
    }

    &-starting-label {
      font-size: pxToRem(12px);
      font-weight: 400;
      padding: 0 $spacing-md;
    }

    &-info {
      font-size: pxToRem(15px);
      font-weight: 400;
      padding: 0 $spacing-md;
    }

    &-salary {
      display: flex;
      padding-top: $spacing-sm;
      gap: $spacing-sm;
      align-items: center;

      &-item {
        font-weight: 700;
        width: 38px;
        height: 30px;
        padding: $spacing-xs $spacing-sm;
        background-color: var(--bg-grey);
        border-radius: 5px;
        cursor: pointer;

        &[is-select='true'] {
          background-color: var(--primary);
          color: var(--white);
        }
      }
    }
  }

  &-list {
    flex-grow: 1;
    padding: $spacing-md;
    display: flex;
    flex-direction: column;

    p {
      display: flex;
      align-items: center;
      gap: $spacing-sm;
      row-gap: 0px;
      padding: $spacing-xs 0;
      font-size: pxToRem(15px);
      &:last-child {
        flex-wrap: wrap;
      }
      & > span:last-of-type {
        flex: 100%;
        padding-left: 38px;
      }

      img {
        width: 28px;
        height: 28px;
        text-align: center;
      }
    }
  }

  &-button {
    padding: 0 $spacing-md;
  }
}
</style>
