<template>
  <NuxtLayout :key="layout" :name="layout">
    <div :key="locale" class="sp-error-page">
      <div class="sp-error-page-content">
        <h1>{{ $t('errorPage.error404Title') }}</h1>
        <div class="sp-error-page-image">
          <ImageLazy src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/404-header.svg" alt="404 not found" />
        </div>
        <div v-show="tld === 'FR'" class="sp-error-page-description">
          <a href="https://www.youtube.com/watch?v=-xUfcJS0aZk" target="_blank">
            <img src="@/public/404/youtube-video.png" alt="StockPro youtube video" />
          </a>
          <div class="sp-error-page-description-content">
            <p>
              {{ $t('errorPage.error404Description') }}
            </p>
          </div>
        </div>
      </div>
      <ZendeskButton />
    </div>
  </NuxtLayout>
</template>

<script setup>
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'
import { useStore } from 'vuex'

const { tld, locale } = useCurrentLocale()

const clubStore = useClubStore()

const layout = computed(() => {
  const clubStore = useClubStore()
  const store = useStore()
  return store.getters['proArea/isProDomain'] ? 'pro' : clubStore.layout
})

onMounted(() => {
  if (clubStore.isClub) {
    const colors = clubStore.clubConfig?.theme?.colors
    if (colors) {
      const { setTheme } = useTheme()
      setTheme({ theme: { colors }, favicon: '/favicon.ico' })
    }
  }
})

try {
  const { defaultHead, defaultSeo, customKey } = useHeadMeta()
  customKey.value = 'error'
  useHead(defaultHead())
  useSeoMeta(defaultSeo())
} catch (e) {
  console.log('error from error page', e)
}
</script>

<style lang="scss">
.sp-error-page {
  width: 100%;
  margin-bottom: 30px;
  background: url('https://d3brsr9pdomwt0.cloudfront.net/illustrations/blue-background-image.svg'),
    url('https://d3brsr9pdomwt0.cloudfront.net/illustrations/green-background-image.svg');
  background-repeat: no-repeat, no-repeat;
  background-position:
    0 36px,
    right 90px;
  &-content {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    padding-top: 100px;
    padding-left: 20%;
    padding-right: 20%;
  }

  &-image > img {
    width: 100%;
  }

  h1 {
    font-family: 'Avenir';
    text-align: center;
    font-size: 1.8rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: var(--dark-blue);
    margin-bottom: 20px;
  }

  &-description {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%;
    & p {
      font-family: 'Avenir';
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: var(--dark-grey);
    }

    & img {
      width: 125px;
      height: 125px;
    }

    & > a {
      align-self: flex-end;
    }

    &-content {
      display: flex;
      flex-flow: column nowrap;
      margin-left: 15px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1439px) {
  .sp-error-page {
    background: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .sp-error-page-content {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sp-error-page-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .sp-error-page {
    margin-bottom: 20px;

    &-content {
      flex-flow: column nowrap;
      overflow: hidden;
      padding-top: 70px;
      padding-left: 3%;
      padding-right: 3%;
    }

    &-image > img {
      width: 100%;
    }

    h1 {
      margin-bottom: 10px;
    }

    &-description {
      flex-flow: column nowrap;
      margin-top: 20px;
      & > a {
        align-self: flex-start;
      }

      &-content {
        flex-flow: column nowrap;
        margin: 0;
      }
    }
  }
}
</style>
