export const state = () => ({
  list: [],
})

export const mutations = {
  add(state, utm) {
    if (!state.list.includes(utm)) state.list.push(utm)
  },
  remove(state, utm) {
    state.list.splice(state.list?.indexOf(utm), 1)
  },
}

export default {
  state,
  mutations,
  namespaced: true,
}
