import Repository from './repository'

export default class QuoteRepository extends Repository {
  get(uid, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}quotes/${uid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.quote)
  }

  search(mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}quotes`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.quotes)
  }

  async getQuotes(companyUid, userUid, params, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/quotes`
    const config = {
      params,
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      results: response.quotes,
      total: parseInt(response.total, 10),
    }))
  }

  async getQuote(companyUid, userUid, quoteUid, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/quotes/${quoteUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.quote)
  }

  async getSales(companyUid, params, mapping = this.mapper.searchMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/quotes`
    const config = {
      params,
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      results: response.quotes,
      total: parseInt(response.total, 10),
    }))
  }

  async getSale(companyUid, quoteUid, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/quotes/${quoteUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.quote)
  }

  async updateSaleQuote(companyUid, quote, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/quotes/${quote.uid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { quote }, config).then(response => response.quote)
  }

  async updatePurchaseQuote(companyUid, userUid, quote, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/quotes/${quote.uid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { quote }, config).then(response => response.quote)
  }

  async approveQuote(companyUid, quoteUid, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/quotes/${quoteUid}/approve`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, {}, config).then(response => response.quote)
  }

  async validateQuote(companyUid, quoteUid, amount, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/quotes/${quoteUid}/order`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { quote: { negotiated_price: amount } }, config).then(response => response.quote)
  }

  async cancelQuote(companyUid, userUid, quoteUid, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/quotes/${quoteUid}/cancel`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, {}, config).then(response => response.quote)
  }

  async refuseQuote(companyUid, quoteUid, mapping = this.mapper.quoteMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/quotes/${quoteUid}/refuse`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, {}, config).then(response => response.quote)
  }
}
