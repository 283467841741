<template>
  <div class="locator">
    <!-- TODO : this component could probably be merged with LdAgecSection component -->
    <div class="inner-container fad">
      <div class="locator__flex">
        <div v-if="image?.filename" class="locator__img">
          <ImageLazy :src="image.filename" :alt="image.alt" :title="image.title" />
        </div>
        <div class="locator__txt">
          <h2 class="locator__title">
            {{ title }}
          </h2>
          <div class="landing-download__subtitle" v-html="richText" />
          <main-button
            :label="$t('actions.freeDownload')"
            :link="downloadAppLink"
            tag="a"
            target="_blank"
            icon-position="RIGHT"
            class="locator__btn"
            class-variant="btn-white-blue full-width-sm"
            @click="trackDownloadLink"
          >
            <icon-arrow-right />
          </main-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadApp from '@/mixins/download-app-mixin'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import IconArrowRight from '@/icons/IconArrowRight'

export default {
  name: 'SbNetworkSection',
  components: {
    MainButton,
    IconArrowRight,
  },
  mixins: [DownloadApp],
  props: {
    image: { type: Object, required: true },
    cta: { type: String, required: true },
    title: { type: String, required: true },
    text: { type: Object, required: true },
  },
  computed: {
    richText() {
      if (this.text) {
        return renderRichText(this.text)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.locator {
  background-color: var(--landing-color-secondary);
  margin: 60px 0;

  &__title {
    margin-bottom: 20px;
    color: var(--white);
  }

  p {
    color: var(--white);
  }

  &__flex {
    padding: 60px 0;

    @include mqmin($mq-sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > * {
        flex: 0 0 calc(50% - 20px / 2);

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  &__img {
    @include mq($mq-sm) {
      margin-bottom: 40px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
</style>
