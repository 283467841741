import { useStock } from '@/composables/useStock'

export function useCartItem(stock, quantity) {
  const cartItem = {
    uid: stock.uid,
    stock: useStock(stock),
    quantity: quantity,
  }
  return {
    ...cartItem,
  }
}
