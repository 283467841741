<template>
  <div class="inner-container fad container-banner" :class="{ rtl }">
    <div class="container-banner__text">
      <div class="container-banner__text-padding">
        <div class="container-banner__bg" :style="backgroundColor" />
        <h2 class="h1" :style="primaryColorText">
          {{ title }}
        </h2>
        <div class="container-banner__subtitle" :style="`--sbContainerTextColor:${primaryColor};`" v-html="richText" />
        <main-button
          v-if="buttonText"
          :label="buttonText"
          :link="buttonUrl"
          tag="a"
          target="_blank"
          icon-position="RIGHT"
          class-variant="full-width-sm"
          :style-variant="[accentColorText, primaryColorBg]"
        >
          <ImageLazy
            v-if="buttonIcon && buttonIcon.filename"
            :src="`${buttonIcon.filename}`"
            :alt="`${buttonText}`"
            :title="`${buttonText}`"
            with="40px"
            height="40px"
          />
        </main-button>
      </div>
    </div>
    <div class="container-banner__img">
      <ImageLazy
        :src="`${image.filename}`"
        :alt="image.alt || $t('brandName')"
        :title="image.title || $t('brandName')"
      />
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'SbContainerBanner',
  components: {
    MainButton,
  },
  props: {
    title: { type: String, default: '' },
    text: { type: [Object, String], default: null },
    image: { type: Object, default: () => {} },
    rtl: { type: Boolean, default: false },
    buttonText: { type: String, default: '' },
    buttonUrl: { type: String, default: '' },
    buttonIcon: { type: Object, default: () => {} },
    bgColor: { type: String, default: '' },
    primaryColor: { type: String, default: '' },
    accentColor: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    button_text: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    button_url: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    button_icon: { type: Object, default: () => {} },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    primary_color: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    accent_color: { type: String, default: '' },
  },
  computed: {
    richText() {
      if (this.text) {
        return renderRichText(this.text)
      }
      return null
    },
    backgroundColor() {
      return { 'background-color': this.bgColor }
    },
    primaryColorBg() {
      return { 'background-color': this.primaryColor }
    },
    primaryColorText() {
      return { color: this.primaryColor }
    },
    accentColorBg() {
      return { 'background-color': this.accentColor }
    },
    accentColorText() {
      return { color: this.accentColor }
    },
  },
}
</script>

<style lang="scss">
.container-banner {
  margin: 60px auto !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: var(--sbContainerTextColor);
  }

  @include mq($mq-sm) {
    display: block;
  }

  & > * {
    flex: 1 1 0px;
    margin-right: 20px;

    @include mq($mq-sm) {
      margin-right: 0;
    }
  }

  &__text {
    border-radius: 20px;
    position: relative;

    h2 {
      font-size: pxToRem(25px);
      margin-bottom: 20px;
    }
  }
  &__subtitle {
    margin-bottom: 20px;
  }

  &__text-padding {
    padding: 30px;
    padding-right: 0;

    @include mq($mq-sm) {
      padding: 30px;
    }

    @include mq($mq-xs) {
      padding: 20px;
    }

    & > * {
      position: relative;
    }
  }

  &__img {
    position: relative;

    @include mq($mq-sm) {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(200% + 40px);
    z-index: 0;
    border-radius: 20px;

    @include mq($mq-sm) {
      width: 100%;
    }
  }

  &.rtl {
    flex-direction: row-reverse;
    .container-banner__bg {
      right: 0;
      left: auto;
    }
  }
}
</style>
