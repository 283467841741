import Repository from './repository'

export default class AutocompleteRepository extends Repository {
  async getMarketplace(filters = []) {
    const url = `${this.backendUrl}autocomplete/marketplace`
    const config = {
      params: {
        limit: 5,
      },
    }
    if (filters && filters.length > 0) {
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params['search[query]'] = keywordsFilter.value
    }
    return this.fetcher.get(url, config).then(response => {
      return response.results
    })
  }

  async getStock(companyUid, filters = []) {
    const url = `${this.backendUrl}companies/${companyUid}/autocomplete/stocks`
    const config = {
      params: {
        limit: 5,
      },
    }
    if (filters && filters.length > 0) {
      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params['search[query]'] = keywordsFilter.value
    }
    return this.fetcher.get(url, config).then(response => {
      return response.results
    })
  }
}
