import { useCartStore } from '@/stores/cart'

export default defineNuxtPlugin(() => {
  const cartStore = useCartStore()
  let isProcessing = false

  const sharedActions = [
    'addItem',
    'updateItem',
    'remove',
    'setDeliveryMethod',
    'removeDeliveryMethod',
    'addCoupon',
    'clearCoupons',
    'setAddress',
    'setSyncError',
    'setIsReady',
    'setCartUid',
    'setRemoteCart',
    'clearCart',
    'clearRemoteCart',
  ]

  cartStore.$onAction(({ name, after, onError, args }) => {
    after(() => {
      if (sharedActions.includes(name) && !isProcessing) {
        const data = {
          name,
          args,
        }
        localStorage.setItem('cart-actions', JSON.stringify(data))
      }
    })
    onError(error => {
      console.error(`Action execution error "${name}":`, error)
    })
  })

  window.addEventListener('storage', event => {
    if (event.key === 'cart-actions' && event.newValue) {
      const action = JSON.parse(event.newValue)
      isProcessing = true
      cartStore[action.name](action.args[0])
      isProcessing = false
    }
  })
})
