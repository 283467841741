export function useCurrentLocale() {
  const i18nStore = useI18nStore()

  return {
    country: i18nStore?.country,
    locale: i18nStore?.locale,
    tld: i18nStore?.tld,
    langCode: (...args) => i18nStore?.langCode(...args),
  }
}
