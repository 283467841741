import { useMerchants } from '@/stores/merchants'
import { useStore } from 'vuex'

const defaultFilter = {
  label: null,
  value: null,
  query: null,
  type: null, // 'CATEGORY', 'BRAND', etc
  display: true,
  data: null,
  silent: false,
  testId: null,
}

export function useQuery() {
  const store = useStore()
  const route = useRoute()
  const { $i18n } = useNuxtApp()

  const recursiveFilter = (item, type, query, label, testId) => {
    let filter = {
      label: label || item.name,
      value: item.uid,
      query,
      data: {},
      type,
      testId,
    }
    if (item.parent) {
      filter.data.parent = item.parent
    }
    if (item.children) {
      filter.data.children = item.children.map(child => {
        let label = child.name
        if (type === 'STORAGE') {
          label = `${child.name} (${child.stocks_count})`
        }
        return recursiveFilter(child, type, query, label)
      })
    }
    return filter
  }

  const getPage = () => {
    let { page } = route.query
    if (page === undefined || page === null || Number.isNaN(page)) page = 1

    page = parseInt(page, 10)
    page = page <= 0 ? 1 : page
    return page
  }
  const getPerPage = () => {
    let { perPage } = route.query
    if (perPage === undefined || perPage === null || Number.isNaN(perPage)) perPage = 15

    perPage = parseInt(perPage, 10)
    perPage = perPage <= 0 ? 1 : perPage
    return perPage
  }

  const getFilters = (saveOnStore = true) => {
    let filters = []

    const {
      query,
      brands,
      bookedBy,
      categories,
      storages,
      hasAlert,
      isPublic,
      locality,
      delivery,
      has_pick_up,
      minPrice,
      maxPrice,
      orderBy,
      sortBy,
      sortDirection,
      merchants,
      company,
      withVAT,
      channel,
      freshness,
      bestDeals,
      tags,
      page,
      perPage,
      merchantTags,
      merchant_tags,
      distance,
    } = route.query

    if (query) {
      filters.push({
        label: query,
        value: query,
        query: 'query',
        type: 'QUERY',
      })
    }

    if (company) {
      filters.push({
        label: company,
        value: company,
        query: 'company',
        type: 'COMPANY',
      })
    }

    if (delivery) {
      filters.push({
        label: $i18n.t('search.deliveryAvailable'),
        value: true,
        query: 'delivery',
        type: 'DELIVERY',
      })
    }

    if (has_pick_up) {
      filters.push({
        label: $i18n.t('search.pickupAvailable'),
        value: true,
        query: 'has_pick_up',
        type: 'PICKUP',
      })
    }

    if (distance) {
      filters.push({
        value: distance,
        query: 'distance',
        type: 'DISTANCE',
        display: false,
      })
    }

    if (minPrice) {
      filters.push({
        label: $i18n.t('search.minPrice', minPrice),
        value: minPrice,
        query: 'minPrice',
        type: 'MIN_PRICE',
      })
    }

    if (maxPrice) {
      filters.push({
        label: $i18n.t('search.maxPrice', maxPrice),
        value: maxPrice,
        query: 'maxPrice',
        type: 'MAX_PRICE',
      })
    }

    if (withVAT) {
      filters.push({
        label: null,
        value: withVAT,
        query: 'withVAT',
        type: 'WITH_VAT',
        display: false,
      })
    }

    if (orderBy) {
      filters.push({
        label: null,
        value: decodeURI(orderBy),
        query: 'orderBy',
        type: 'ORDER_BY',
        display: false,
      })
    }

    if (sortBy && sortDirection) {
      filters.push({
        label: null,
        value: sortBy,
        query: 'sortBy',
        type: 'SORT_BY',
        display: false,
      })
      filters.push({
        label: null,
        value: sortDirection,
        query: 'sortDirection',
        type: 'SORT_DIRECTION',
        display: false,
      })
    }

    if (locality) {
      const localityFilters = decodeURIComponent(locality)?.split('+')
      if (localityFilters.length > 0) {
        const localityFilter = {
          label: localityFilters[0],
          value: decodeURIComponent(locality),
          query: 'locality',
          type: 'LOCALITY',
          display: false,
        }
        filters.push(localityFilter)
      }
    }

    const freshnessFilter = []

    if (freshness) {
      const freshnessSplit = freshness?.split(',')

      freshnessSplit.forEach(value => {
        freshnessFilter.push({
          label: $i18n.t(`search.freshness${value}`),
          value: value,
          query: 'mkp_freshness',
          type: 'FRESHNESS',
        })
      })
    }
    filters.push(...freshnessFilter)

    const bestDealsFilter = []

    if (bestDeals) {
      const bestDealsSplit = bestDeals?.split(',')

      bestDealsSplit.forEach(value => {
        const bdSplit = value?.split('_')

        bestDealsFilter.push({
          label: $i18n.t(`search.bestDeals${bdSplit[0]}${bdSplit[1]}`),
          value: value,
          query: `${bdSplit[1] === '-' ? 'under_' : ''}best_deal`,
          type: 'BESTDEALS',
        })
      })
    }
    filters.push(...bestDealsFilter)

    const brandsFilter = []
    if (brands) {
      const brandUids = brands?.split(',')
      brandUids.forEach(uid => {
        const brand = store?.getters['brands/brandByUid'](uid)
        if (brand) {
          brandsFilter.push({
            label: brand.name,
            value: brand.uid,
            query: 'brands',
            type: 'BRAND',
          })
        }
      })
    }
    filters.push(...brandsFilter)

    const bookedByFilter = []
    if (bookedBy) {
      const userUids = bookedBy?.split(',')
      userUids.forEach(uid => {
        const user = store?.getters['users/userWithBookedStockByUid'](uid)
        if (user) {
          bookedByFilter.push({
            // label: `${user.first_name} ${user.last_name} (${user.booked_products_count})`,
            label: `${user.first_name} ${user.last_name}`,
            value: user.uid,
            query: 'booked_by',
            type: 'BOOKED_BY',
          })
        }
      })
    }
    filters.push(...bookedByFilter)

    const storagesFilter = []
    if (storages) {
      const storageUids = storages?.split(',')
      storageUids.forEach(uid => {
        const storage = store?.getters['storages/storageByUid'](uid)
        if (storage) {
          storagesFilter.push(
            recursiveFilter(storage, 'STORAGE', 'storages', `${storage.name} (${storage.stocks_count})`)
          )
        }
      })
    }
    filters.push(...storagesFilter)

    const categoriesFilter = []
    if (categories) {
      const categoryUids = categories?.split(',')
      categoryUids.forEach(uid => {
        const category = store?.getters['categories/availableCategoryByUid'](uid)
        if (category) {
          categoriesFilter.push({
            label: category.name,
            value: category.uid,
            query: 'categories',
            type: 'CATEGORY',
          })
        }
      })
    }
    filters.push(...categoriesFilter)

    if (hasAlert) {
      filters.push({
        label: $i18n.t('search.has_alert'),
        value: true,
        query: 'hasAlert',
        type: 'HAS_ALERT',
      })
    }

    if (isPublic) {
      filters.push({
        label: $i18n.t('search.is_public'),
        value: true,
        query: 'isPublic',
        type: 'IS_PUBLIC',
      })
    }

    const merchantsFilter = []
    if (merchants) {
      const merchantUids = merchants?.split(',')
      const merchantStore = useMerchants()
      merchantUids.forEach(uid => {
        const merchant = merchantStore.merchantByUid(uid)
        if (merchant) {
          merchantsFilter.push({
            label: merchant.name,
            value: merchant.uid,
            query: 'merchants',
            type: 'MERCHANT',
          })
        }
      })
    }
    filters.push(...merchantsFilter)

    const channelsFilters = []
    if (channel) {
      let channelsQueries = channel?.split(',')
      channelsQueries.forEach(channel => {
        channelsFilters.push({
          value: channel,
          query: 'channel',
          type: 'CHANNEL',
        })
      })
    }
    filters.push(...channelsFilters)

    if (tags) {
      let tagsQueries = tags?.split(',')
      tagsQueries.forEach(tag => {
        filters.push({
          label: null,
          value: tag,
          query: 'tags',
          type: 'TAGS',
          display: false,
        })
      })
    }
    if (merchant_tags || merchantTags) {
      let merchantTagsQueries = (merchant_tags || merchantTags)?.split(',')
      merchantTagsQueries.forEach(tag => {
        filters.push({
          label: null,
          value: tag,
          query: 'merchant_tags',
          type: 'MERCHANT_TAGS',
          display: false,
        })
      })
    }

    if (page) {
      filters.push({
        label: null,
        value: Number(page),
        query: 'page',
        type: 'PAGE',
        display: false,
      })
    }
    if (perPage) {
      filters.push({
        label: null,
        value: Number(perPage),
        query: 'perPage',
        type: 'PER_PAGE',
        display: false,
      })
    }
    filters = filters.map(filter => ({ ...defaultFilter, ...filter }))
    if (saveOnStore) {
      store?.commit('filters/setActiveFilters', filters)
    }
    return {
      filters,
    }
  }
  return {
    getPage,
    getPerPage,
    getFilters,
    recursiveFilter,
  }
}
