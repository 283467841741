import { useCurrentLocale } from '@/composables/useCurrentLocale'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const axeptioDisabled = useCookie('x-axeptio_disabled')
  if (location?.search?.includes('axeptio_disabled=1')) {
    axeptioDisabled.value = '1'
  }
  if (import.meta.client && config.public.AXEPTIO_ENABLED === '1' && axeptioDisabled.value !== '1') {
    const { tld, locale, country } = useCurrentLocale()
    let domain = location?.host?.replace('www.', '')?.split(':').shift()
    let currentTld = tld?.toLowerCase()
    let lang = [currentTld, locale]
    lang = lang.filter((x, i) => lang?.indexOf(x) === i).slice(0, 2)
    let axeptioKey = country?.axeptioKey
    // console.log(`axeptio stockpro-${lang.join('-')}`, axeptioKey || 'nokey', domain)
    window.axeptioSettings = {
      clientId: axeptioKey,
      userCookiesDomain: domain || config.public.PRODUCTION_DOMAIN,
      cookiesVersion: `stockpro-${lang.join('-')}`,
    }
    ;(function (d, s) {
      var t = d.getElementsByTagName(s)[0],
        e = d.createElement(s)
      e.async = true
      e.src = '//static.axept.io/sdk-slim.js'
      t.parentNode.insertBefore(e, t)
    })(document, 'script')
    window._axcb = window._axcb || []
    window._axcb.push(function (axeptio) {
      axeptio.on('cookies:complete', function () {
        window.dataLayer = window.dataLayer || []
      })
      // axeptio.on('consent:saved', function () {
      //   window.location.reload()
      // })
    })
  }
})
