export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('custom-attr', {
    mounted(el, binding) {
      el.setAttribute(binding.arg, Array.isArray(binding.value) ? binding.value.join(' ') : binding.value)
    },
    ssrRender(el, binding) {
      el.setAttribute(binding.arg, Array.isArray(binding.value) ? binding.value.join(' ') : binding.value)
    },
  })
})
