import Repository from './repository'

export default class DownloadFilesRepository extends Repository {
  async whiteBook(token) {
    const url = `${this.backendUrlV2}download_files/white_book/${token}`
    let config = {}
    return this.fetcher.get(url, config).then(response => response)
  }

  async monthlyReport(token) {
    const url = `${this.backendUrl}download_files/monthly_report/${token}`
    let config = {}
    return this.fetcher.get(url, config).then(response => response)
  }
}
