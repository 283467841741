export const state = () => ({
  all: [],
})

export const getters = {
  brandBySlug: state => slug => state.all.find(c => c.slug === slug),
  brandByUid: state => uid => state.all.find(c => c.uid === uid),
}

export const mutations = {
  setAllBrands(state, brands) {
    state.all = brands
  },
  addBrands(state, brands) {
    let allBrands = state.all.concat(brands)
    allBrands = allBrands.filter((b, index, self) => index === self.findIndex(brand => b?.uid === brand?.uid))
    state.all = allBrands
  },
}

export const actions = {
  async getAll({ commit }) {
    const localePath = useLocalePath()
    const { $api } = useNuxtApp()
    try {
      await $api()
        .brand.getBrands()
        .then(rawBrands => {
          commit('setAllBrands', rawBrands)
        })
    } catch (e) {
      console.log(e)
      return localePath({ statusCode: 404, message: e.message })
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
