export const state = () => ({
  step: 0,
  proSelected: false,
  company: null,
  matchingCompanies: [],
  pendingConfirmation: false, // Need to confirm account via phone
  pendingValidation: false, // Need to be reviewed by StockPro
})

export const getters = {
  getStep: state => state.step,
  getProSelected: state => state.proSelected,
  getAccount: state => state.account,
  matchingCompanies: state => state.matchingCompanies,
  company: state => state.company,
  pendingConfirmation: state => state.pendingConfirmation,
  pendingValidation: state => state.pendingValidation,
}

export const mutations = {
  setStep(state, step) {
    state.step = step
  },
  setProSelected(state, proSelected) {
    state.proSelected = proSelected
  },
  setCompany(state, company) {
    state.company = company
  },
  setMatchingCompanies(state, companies) {
    state.matchingCompanies = companies
  },
  setPendingConfirmation(state, pendingConfirmation) {
    state.pendingConfirmation = pendingConfirmation
  },
  setPendingValidation(state, pendingValidation) {
    state.pendingValidation = pendingValidation
  },
}

export default {
  state,
  getters,
  mutations,
  namespaced: true,
}
