export function countriesData(config) {
  const countries = [
    {
      name: ['France'],
      intlName: 'France',
      code: 'fr',
      iso: 'FR',
      countrySelection: 'Sélectionnez StockPro France',
      availableLanguages: ['fr'],
      flag: '/flags/FR.png',
      host: config.public.PRODUCTION_DOMAIN,
      dialingCode: '+33',
      // phoneRegex: '^(?:(?:+|00)33|0)s*[1-9](?:[s.-]*d{2}){3}s*d{2}$',
      paymentMethods: ['CARD', 'PAYPAL'],
      stripeApiKey: config.public.STRIPE_API_KEY,
      axeptioKey: config.public.AXEPTIO_KEY_FR,
      zendeskKey: { fr: config.public.ZENDESK_KEY_FR },
      localCompanyCode: 'SIREN',
      localCompanyCodeLength: 9,
      metaTitle: { fr: 'StockPro, Plateforme Réemploi, Vente matériaux de construction pas cher' },
      metaDescription: {
        fr: "StockPro, la plateforme de réemploi de matériaux de construction et outillage neufs et pas cher. Achetez à bas prix et favorisez l'économie circulaire.",
      },
      VAT: 1.2,
      demoLink: {
        fr: 'https://calendly.com/stephane-stockpro/demostockpro',
      },
      phoneNumber: '+33 1 77 62 38 80',
      trustpilotLink: 'https://fr.trustpilot.com/review/stock-pro.fr',
      trustpilotDomainID: '5f732a9ec59691000114786f',
      faqLink: {
        fr: 'https://stock-prohelp.zendesk.com/hc/fr',
      },
      contactFormLink: {
        fr: 'https://stock-prohelp.zendesk.com/hc/fr/requests/new',
      },
      footerAbout: {
        fr: 'En France, 5 milliards € de matériaux neufs sont jetés chaque année. <br>Avec StockPro, on allège notre bilan carbone grâce à <strong>une vraie démarche eco-responsable</strong>, avec la 1ère plateforme de réemploi de matériaux neufs en France 🇫🇷',
      },
    },
    {
      name: ['Nederlands'],
      intlName: 'Nederlands',
      code: 'nl',
      iso: 'NL',
      countrySelection: 'Selecteer StockPro Nederlands',
      availableLanguages: ['nl'],
      flag: '/flags/NL.png',
      host: `${config.public.PRODUCTION_DOMAIN?.split('.').shift()}.nl`,
      dialingCode: '+31',
      // phoneRegex: '^(+31|0)(6[d]{1}|[1-9][d]{1})-?[d]{4}s?[d]{3}$',
      paymentMethods: ['CARD', 'IDEAL', 'PAYPAL'],
      stripeApiKey: config.public.STRIPE_API_KEY,
      axeptioKey: config.public.AXEPTIO_KEY_NL,
      zendeskKey: { nl: config.public.ZENDESK_KEY_NL },
      localCompanyCode: 'KVK',
      localCompanyCodeLength: 8,
      metaTitle: { nl: 'StockPro, Verkoop van hergebruikte bouwmaterialen en platform voor hergebruik' },
      metaDescription: {
        nl: 'StockPro, het goedkope platform voor hergebruik van nieuwe bouwmaterialen en gereedschappen. Koop goedkoop in en neem deel in de circulaire economie.',
      },
      VAT: 1.21,
      demoLink: {
        nl: 'https://calendly.com/stephane-stockpro/demonstration-stockpro-nl',
      },
      phoneNumber: '+31 85 107 13 75',
      trustpilotLink: 'https://www.trustpilot.com/review/stock-pro.nl',
      trustpilotDomainID: '6464bcf8cf21107bf0056a0e',
      faqLink: {
        nl: 'https://stock-prohelpnl.zendesk.com/hc/nl',
      },
      contactFormLink: {
        nl: 'https://stock-prohelpnl.zendesk.com/hc/nl/requests/new',
      },
      footerAbout: {
        nl: 'De Nederlandse overheid streeft naar een volledig circulaire economie in 2050. Slechts 8% van de hergebruikte bouwmaterialen vindt een gelijkwaardige of verbeterde toepassing in het bouwproces. <br>Tegen deze achtergrond zet StockPro zich in om praktijken en houdingen in de bouwsector te veranderen door een unieke en innovatieve oplossing te bieden. Het 1e platform voor hergebruik van nieuwe materialen in Nederland 🇳🇱',
      },
    },
    {
      name: ['Belgique', 'Belgie'],
      intlName: 'Belgium',
      code: 'be',
      iso: 'BE',
      countrySelection: 'Sélectionnez StockPro Belgique / Selecteer StockPro Belgie',
      availableLanguages: ['fr', 'nl'],
      flag: '/flags/BE.png',
      host: `${config.public.PRODUCTION_DOMAIN?.split('.').shift()}.be`,
      dialingCode: '+32',
      // phoneRegex: '^(0|+32) ?([1-9]{1})(d{2}) ?(d{2}) ?(d{2})$',
      paymentMethods: ['CARD', 'BANCONTACT', 'PAYPAL'],
      stripeApiKey: config.public.STRIPE_API_KEY,
      axeptioKey: config.public.AXEPTIO_KEY_BE,
      zendeskKey: { fr: config.public.ZENDESK_KEY_BE_FR, nl: config.public.ZENDESK_KEY_BE_NL },
      localCompanyCode: 'BCE',
      localCompanyCodeLength: 12,
      metaTitle: {
        fr: 'StockPro, Vente matériaux de construction réutilisés et Plateforme Réemploi',
        nl: 'StockPro, Verkoop van hergebruikte bouwmaterialen en platform voor hergebruik',
      },
      metaDescription: {
        fr: "StockPro, la plateforme de réemploi de matériaux de construction et outillage neufs et pas cher. Achetez à bas prix et favorisez l'économie circulaire.",
        nl: 'StockPro, het goedkope platform voor hergebruik van nieuwe bouwmaterialen en gereedschappen. Koop goedkoop in en neem deel in de circulaire economie.',
      },
      VAT: 1.21,
      demoLink: {
        fr: 'https://calendly.com/stephane-stockpro/demonstration-stockpro-be-fr',
        nl: 'https://calendly.com/stephane-stockpro/demonstration-stockpro-be-nl',
      },
      phoneNumber: '+32 2 320 99 05',
      trustpilotLink: 'https://www.trustpilot.com/review/stock-pro.be',
      trustpilotDomainID: '6463802dcf21107bf0055150',
      faqLink: {
        fr: 'https://stock-prohelpbe-fr.zendesk.com/',
        nl: 'https://stock-prohelpbe-nl.zendesk.com/',
      },
      contactFormLink: {
        fr: 'https://stock-prohelpbe-fr.zendesk.com/hc/fr/requests/new',
        nl: 'https://stock-prohelpbe-nl.zendesk.com/hc/nl/requests/new',
      },
      footerAbout: {
        fr: 'En Belgique, 10 millions de tonnes de matériaux sont évacués des chantiers tous les ans et seulement 1% auront une seconde vie. <br>Avec StockPro, on allège notre bilan carbone grâce à une vraie démarche eco-responsable, avec la 1ère Plateforme de réemploi de matériaux neufs en Belgique 🇧🇪',
        nl: 'In België wordt jaarlijks 10 miljoen ton materiaal geëvacueerd van bouwwerven en slechts 1% krijgt een tweede leven. <br>Met StockPro verminderen we onze koolstofbalans dankzij een echte eco-verantwoordelijke aanpak, met het 1ste platform voor het hergebruik van nieuwe materialen in België 🇧🇪',
      },
    },
  ]

  return {
    countries,
  }
}
