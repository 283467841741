export default class Repository {
  constructor({ runtimeConfig, fetcher, backendUrl, mapper, storyApi }) {
    this.runtimeConfig = runtimeConfig
    this.fetcher = fetcher
    this.backendUrl = backendUrl
    this.backendUrlV2 = backendUrl.replace('/api/v1', '/api/v2')
    this.mapper = mapper
    this.storyApi = storyApi
    this.token = null
  }
}
