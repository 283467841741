import { useCartStore } from '@/stores/cart'

export default defineNuxtPlugin(() => {
  const cartStore = useCartStore()
  const savedStateCart = localStorage.getItem('cart')
  if (savedStateCart && cartStore.items?.length === 0 && JSON.parse(savedStateCart)?.items?.length > 0) {
    cartStore.setCart(JSON.parse(savedStateCart))
  }

  cartStore.$onAction(({ name, store, after, onError }) => {
    after(() => {
      // console.log('store.$state', name, store.$state)
      try {
        localStorage.setItem('cart', JSON.stringify(store.$state))
      } catch (e) {
        console.error('Error for saving in localStorage :', e)
      }
    })

    onError(error => {
      console.error(`Action execution error "${name}":`, error)
    })
  })
})
