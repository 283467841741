<template>
  <div
    class="sb-seo-content"
    :data-test-id="elementTest"
    :style="`--seo-content-border-color:${border_color};--seo-content-bg-color:${background_color}`"
  >
    <div v-if="image?.filename" class="sb-seo-content-flex" :class="{ rtl }" :data-test-id="elementTest + '-flex'">
      <div v-if="image?.filename" class="sb-seo-content-img" :data-test-id="elementTest + '-img'">
        <ImageLazy
          :src="image_url || image?.filename"
          :alt="image?.alt || richTitle || $t('brandName')"
          :title="image?.title || richTitle || $t('brandName')"
          :background-image="true"
          :width="imageWidth"
          :height="imageHeight"
          :max-width="imageMaxWidth"
          max-height="100%"
        />
      </div>
      <div class="sb-seo-content-txt" :data-test-id="elementTest + '-txt'">
        <div v-if="richTitle" class="sb-seo-content-title" :data-test-id="elementTest + '-title'" v-html="richTitle" />
        <div
          v-if="richDescription"
          class="sb-seo-content-description"
          :data-test-id="elementTest + '-description'"
          v-html="richDescription"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Mobile from '@/mixins/mobile-mixin'

export default {
  name: 'SbSeoContent',
  mixins: [Mobile],
  props: {
    title: { type: [Object, String], default: undefined },
    description: { type: [Object, String], default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    image_url: { type: String, default: '' },
    image: { type: Object, default: () => {} },
    rtl: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    description_show_more: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    background_color: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    border_color: { type: String, default: '' },
    elementTest: { type: String, default: '' },
  },
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    richTitle() {
      if (this.title) {
        return renderRichText(this.title)
      }
      return null
    },
    richDescription() {
      if (this.description) {
        const desc = renderRichText(this.description)
        return this.description_show_more && !this.showMore
          ? desc?.split('[break]')?.[0] || desc
          : desc.replace('[break]', '')
      }
      return null
    },
    imageWidth() {
      return this.isSm ? '320' : this.isMd ? '100%' : '40vw'
    },
    imageMaxWidth() {
      return this.isMd ? '100%' : '656'
    },
    imageHeight() {
      return this.isMd ? '360' : '100%'
    },
  },
}
</script>

<style lang="scss">
.sb-seo-content {
  border: 1px solid var(--seo-content-border-color);
  background-color: var(--seo-content-bg-color);
  border-radius: 5px;
  overflow: hidden;

  @include mqmin($mq-md) {
    &-flex {
      display: flex;
      // gap: $spacing-xl;
      &.rtl {
        flex-direction: row-reverse;
        .sb-seo-content-title {
          padding-bottom: $spacing-md;
        }
      }
    }
  }
  &-txt {
    gap: $spacing-xl;
    align-items: center;
    padding: $spacing-xl !important;
  }
  &-title {
    & > * {
      font-size: pxToRem(30px) !important;
      font-weight: 800;
      @include mq($mq-md) {
        font-size: pxToRem(20px) !important;
      }
    }
    @include mqmin($mq-md) {
      display: flex;
      gap: $spacing-sm;
    }
  }
  &-description {
    flex: 1;
    padding-top: $spacing-md;
    & > * {
      // font-size: pxToRem(15px);
      b,
      strong {
        font-weight: 800;
      }
      @include mq($mq-md) {
        font-size: pxToRem(15px) !important;
      }
    }
  }
  &-img {
    max-width: 100%;
    &,
    & > div {
      width: 100% !important;
    }
  }
}
</style>
