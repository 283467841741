import { defineStore } from 'pinia'
import reject from 'lodash.reject'

export const useCartStore = defineStore('cart', {
  state: () => ({
    items: [],
    deliveryMethods: [],
    coupons: [],
    expire: 1,
    syncError: null,
    isReady: false,
    cartUid: null,
    remoteCart: null,
    deliveryAddress: null,
    version: 0,
    utm: {},
  }),

  getters: {
    // "get" to avoid same state names
    getItems: state => state.items,
    getDeliveryMethods: state => state.deliveryMethods,
    getCoupons: state => state.coupons,
    getSyncError: state => state.syncError,
    getIsReady: state => state.isReady,
    getCartUid: state => state.cartUid,
    getRemoteCart: state => state.remoteCart,
    getVersion: state => state.version,
    getIsInCart: state => refUid => !!state.items.find(x => x.stock.uid === refUid),
    getUtm: state => state.utm,
  },

  actions: {
    setCart(cart) {
      Object.assign(this, cart)
    },
    addItem(item) {
      this.items.push({
        stock: item.stock,
        quantity: item.quantity,
      })
    },
    updateItem({ item, index }) {
      this.items[index] = {
        stock: item.stock,
        quantity: item.quantity,
      }
    },
    remove(item) {
      this.items = reject(this.items, el => el.stock.uid === item.stock.uid)
    },
    setDeliveryMethod(deliveryMethod) {
      const index = this.deliveryMethods.findIndex(el => el.merchantUid === deliveryMethod.merchantUid)
      if (index === -1) {
        this.deliveryMethods.push(deliveryMethod)
      } else {
        this.deliveryMethods[index] = deliveryMethod
      }
    },
    removeDeliveryMethod(merchantUid) {
      this.deliveryMethods = reject(this.deliveryMethods, el => el.merchantUid === merchantUid)
    },
    addCoupon(coupon) {
      this.coupons.push(coupon)
    },
    clearCoupons() {
      this.coupons = []
    },
    setAddress(address) {
      if (address.kind === 'SHIPPING') {
        this.deliveryAddress = address
      }
    },
    setSyncError(error) {
      this.syncError = error
    },
    setIsReady(isReady) {
      this.isReady = isReady
    },
    setCartUid(uid) {
      this.cartUid = uid
    },
    setRemoteCart(cart) {
      this.remoteCart = cart
    },
    setVersion(version) {
      this.version = version
    },
    clearCart() {
      this.isReady = false
      this.items = []
      this.coupons = []
      this.remoteCart = null
      this.cartUid = null
      this.deliveryAddress = null
      this.deliveryMethods = []
    },
    clearRemoteCart() {
      this.remoteCart = null
    },
    setUtm(utm) {
      Object.assign(this.utm, utm)
    },
  },
})
