export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) {
    try {
      // console.log('clear datalayer')
      // clear gtm datalayer
      window.dataLayer = window.dataLayer || []
      if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = []
      }

      const lastGtmJsIdx = window.dataLayer.findLastIndex(x => x.event === 'gtm.js' || x.event === 'gtm.load')
      // console.log(lastGtmJsIdx)
      if (lastGtmJsIdx !== -1) {
        window.dataLayer.splice(lastGtmJsIdx + 1)
      }

      // clear piwik sevenTag
      window.sevenTag = window.sevenTag || {}
      if (window.sevenTag?.debugger?.stack) {
        window.sevenTag.debugger.stack.splice(1)
      }

      // clear trustbox
      const els = document.querySelectorAll('.trustpilot-widget')
      if (els.length) {
        const fragment = document.createDocumentFragment()
        els.forEach(el => {
          fragment.appendChild(el) // Move elements to the fragment
        })
        fragment.innerHTML = '' // Clear all elements in the fragment
      }
    } catch (error) {
      console.error('Error clearing data layers:', error)
    }
  }
})
