export const state = () => ({
  phone: null,
  emailOrPhone: null,
  forgotPassword: false,
})

export const getters = {
  phone: state => state.phone,
  emailOrPhone: state => state.emailOrPhone,
  forgotPassword: state => state.forgotPassword,
}

export const mutations = {
  setPhone(state, phone) {
    state.phone = phone
  },
  setEmailOrPhone(state, emailOrPhone) {
    state.emailOrPhone = emailOrPhone
  },
  setForgotPassword(state, forgotPassword) {
    state.forgotPassword = forgotPassword
  },
}

export default {
  state,
  getters,
  mutations,
  namespaced: true,
}
