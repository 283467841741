export const getImage = (src, { modifiers = {} } = {}) => {
  // withQuery requires query parameters as an object, so I parse the modifiers into an object with getQuery
  src = src
    .replace('s3-eu-west-3.amazonaws.com/stockpro-webapp', 'd2tbovyd0ghy3r.cloudfront.net')
    .replace('s3-eu-west-3.amazonaws.com/stockpro-references', 'd2tbovyd0ghy3r.cloudfront.net')
    .replace('s3-eu-west-3.amazonaws.com/stockpro-marketing', 'd2tbovyd0ghy3r.cloudfront.net')
    .replace('d3brsr9pdomwt0.cloudfront.net', 'd2tbovyd0ghy3r.cloudfront.net')
    .replace('d1l9hv9873iyuz.cloudfront.net', 'd2tbovyd0ghy3r.cloudfront.net')
  const url = [`${src}?`]
  if (modifiers.format) {
    url.push(`format=${modifiers.format}`)
  } else {
    url.push(`format=webp`)
  }
  if (import.meta.client && window?.devicePixelRatio >= 2) {
    modifiers.width = modifiers.width * 1.2
    modifiers.height = modifiers.height * 1.2
  }
  if (modifiers.pixelRatio) {
    modifiers.width = Math.round(modifiers.width * modifiers.pixelRatio)
    modifiers.height = Math.round(modifiers.height * modifiers.pixelRatio)
  }
  if (modifiers.width) {
    url.push(`width=${modifiers.width}`)
  }
  if (modifiers.height) {
    url.push(`height=${modifiers.height}`)
  }
  if (modifiers.quality) {
    url.push(`quality=${modifiers.quality}`)
  }
  return {
    url: url.join('&'),
  }
}
