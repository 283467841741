import { useCurrentLocale } from '@/composables/useCurrentLocale'

export function useCategory({
  name,
  uid,
  image_on,
  image_off,
  illustration,
  children,
  parent,
  parent_uid,
  slug,
  slugs,
  mkp_products_count,
  count,
  stock_available,
} = {}) {
  const category = {
    name,
    slug,
    uid,
    imageOn: image_on,
    imageOff: image_off,
    illustration,
    children,
    parent,
    parent_uid,
    productsCount: mkp_products_count !== undefined ? mkp_products_count : count,
    stockAvailable: stock_available,
    isActive: false,
    slugs,
  }
  if (children !== undefined && children !== null) {
    category.children = children?.map(child => useCategory(child))
  } else {
    category.children = []
  }

  const hasChildren = () => {
    return category.children !== null && category.children?.length > 0
  }

  const path = `${category.uid}-${encodeURI(category.key)}`

  const breadcrumbPath = () => {
    const categories = []
    let currentCategory = category

    categories.push(currentCategory)
    currentCategory = currentCategory.parent

    while (currentCategory !== null && currentCategory !== undefined && Object.keys(currentCategory).length !== 0) {
      categories.push(currentCategory)
      currentCategory = currentCategory.parent
    }
    return categories.reverse()
  }

  const firstLevelParentUid = () => {
    let currentCategory = category
    while (currentCategory?.parent && currentCategory?.parent !== null) {
      currentCategory = currentCategory.parent
    }
    return currentCategory?.uid
  }

  const relatedUids = () => {
    const uids = []

    if (hasChildren()) {
      recursiveUids(category.children, uids)
    } else {
      uids.push(category.uid)
    }

    return uids
  }

  const thumbnail = () => {
    if (category.imageOn) {
      return category.imageOn?.replace('upload/', 'upload/c_fill,w_60,h_60,c_thumb,f_auto/')
    }
    return null
  }

  const stockAvailableByCountry = () => {
    const { country } = useCurrentLocale()
    return category.stockAvailable?.[country?.code]
  }

  const recursiveUids = (subcategories, uids) => {
    subcategories.forEach(category => {
      if (category.hasChildren) {
        recursiveUids(category.children, uids)
      } else {
        uids.push(category.uid)
      }
    })
  }

  return {
    ...category,
    hasChildren: hasChildren(),
    path: path,
    breadcrumbPath: breadcrumbPath(),
    firstLevelParentUid: firstLevelParentUid(),
    relatedUids: relatedUids(),
    thumbnail: thumbnail(),
    stockAvailableByCountry: stockAvailableByCountry(),
  }
}
