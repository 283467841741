import { useI18nStore } from '@/stores/i18nStore'

export const state = () => ({
  landingDownload: null,
  landingDynamic: null,
})

export const getters = {
  landingDownload: state => state.landingDownload,
  landingDynamic: state => state.landingDynamic,
}

export const actions = {
  async fetchLandingDownload({ commit }, partner = 'stockpro') {
    const config = useRuntimeConfig()
    const i18nStore = useI18nStore()
    const { $api } = useNuxtApp()
    const { request } = useApiRequest()
    try {
      const response = await request({
        req: $api().storyblok.get(`/landingdownload/${partner}`, {
          version: config.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft',
          uniqueStory: true,
          language: i18nStore.langCode(true),
        }),
      })

      commit('setLandingDownload', response.data.story.content)
    } catch (e) {
      console.error(e)
    }
  },
}

export const mutations = {
  setLandingDownload(state, landingDownload) {
    state.landingDownload = landingDownload
  },
  setLandingDynamic(state, landingDynamic) {
    state.landingDynamic = landingDynamic
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
