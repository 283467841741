import Repository from './repository'

export default class ReferenceRepository extends Repository {
  get(uidOrSlug, mapping = this.mapper.referenceMapping) {
    const url = `${this.backendUrlV2}marketplace/web/references/${uidOrSlug}`
    const config = {
      use_native: true,
      params: {
        with_public_stocks: true,
      },
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.reference)
  }

  update(uid, reference, mapping = this.mapper.referenceMapping) {
    const url = `${this.backendUrl}references/${uid}`
    let config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.put(url, { reference }, config).then(response => {
      return response.reference
    })
  }

  searchReferences(filters = [], page = 1, options, filtersIndicators = false) {
    let defaultsOpts = {
      perPage: 34,
      hasPublicStocks: true,
      mapping: this.mapper.searchMapping,
    }
    let opts = { ...defaultsOpts, ...options }
    // const url = `${this.backendUrlV2}marketplace/web/references/search`
    const url =
      this?.runtimeConfig?.public?.SEARCH_ENDPOINT_V2 === '1'
        ? `${this.backendUrlV2}marketplace/web/references/search`
        : `${this.backendUrl}references`
    const config = {
      params: {
        per_page: opts.perPage,
        page: page,
        has_public_stocks: opts.hasPublicStocks,
        all_companies: true,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
      headers: {
        Mapping: opts.mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      references: response.references,
      total: parseInt(response.total, 10),
      totalStock: response.total_stock_quantities,
      filters: response.filters,
    }))
  }

  list(filters = [], page = 1, options, filtersIndicators = false) {
    let defaultsOpts = {
      perPage: 34,
      hasPublicStocks: true,
      mapping: this.mapper.searchMapping,
    }
    let opts = { ...defaultsOpts, ...options }
    const url = `${this.backendUrlV2}marketplace/web/references`
    const config = {
      params: {
        per_page: opts.perPage,
        page: page,
        has_public_stocks: opts.hasPublicStocks,
        all_companies: true,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
      headers: {
        Mapping: opts.mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      references: response.references,
      total: parseInt(response.total, 10),
      totalStock: response.total_stock_quantities,
      filters: response.filters,
    }))
  }

  stockOffers(referenceUid, { page = 1, perPage = 3 }, filters) {
    const url = `${this.backendUrlV2}marketplace/web/references/${referenceUid}/stock_offers`
    const config = {
      params: {
        per_page: perPage,
        page,
        ...this._formatFiltersForQuery(filters),
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      stock_offers: response.stock_offers,
      total: parseInt(response.total, 10),
    }))
  }

  autocomplete(keywords, filters = [], options) {
    let defaultsOpts = {
      perPage: 3,
      hasPublicStocks: true,
      mapping: this.mapper.searchMapping,
    }
    let opts = { ...defaultsOpts, ...options }
    const autocompleteFilters = [
      {
        value: keywords,
        query: null,
        type: 'KEYWORDS',
      },
      ...filters,
    ]

    return this.searchReferences(autocompleteFilters, 1, opts)
  }

  featured({ perPage = 6, page = 1, filters = [], filtersIndicators = false } = {}) {
    const url = `${this.backendUrlV2}marketplace/web/references/featured`
    const config = {
      params: {
        per_page: perPage,
        page,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
    }
    return this.fetcher.get(url, config).then(response => {
      return {
        references: response.references,
        total: parseInt(response.total, 10),
        totalStock: response.total_stock_quantities,
        filters: response.filters,
      }
    })
  }

  fresh({ perPage, page, filters = [], filtersIndicators = false, mapping = this.mapper.searchMapping }) {
    const url = `${this.backendUrl}references/fresh`
    const config = {
      params: {
        per_page: perPage,
        page,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => {
      return {
        references: response.references,
        total: parseInt(response.total, 10),
        totalStock: response.total_stock_quantities,
        filters: response.filters,
      }
    })
  }

  bestDeals({ perPage, page, filters = [], filtersIndicators = false, mapping = this.mapper.searchMapping }) {
    const url = `${this.backendUrl}references/best-deals`
    const config = {
      params: {
        per_page: perPage,
        page,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
      headers: {
        Mapping: mapping,
      },
    }

    return this.fetcher.get(url, config).then(response => ({
      references: response.references,
      total: parseInt(response.total, 10),
      totalStock: response.total_stock_quantities,
      filters: response.filters,
    }))
  }

  bestSellers({ perPage, page, filters = [], filtersIndicators = false, mapping = this.mapper.searchMapping }) {
    const url = `${this.backendUrl}references/best-sellers`
    const config = {
      params: {
        per_page: perPage,
        page,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => ({
      references: response.references,
      total: parseInt(response.total, 10),
      totalStock: response.total_stock_quantities,
      filters: response.filters,
    }))
  }

  specialDiscounts({ perPage, page, filters = [], filtersIndicators = false, mapping = this.mapper.searchMapping }) {
    const url = `${this.backendUrl}references/price_drops`
    const config = {
      params: {
        per_page: perPage,
        page,
        filters: filtersIndicators,
        ...this._formatFiltersForQuery(filters),
      },
      headers: {
        Mapping: mapping,
      },
    }

    return this.fetcher.get(url, config).then(response => ({
      references: response.references,
      total: parseInt(response.total, 10),
      totalStock: response.total_stock_quantities,
      filters: response.filters,
    }))
  }

  upsell(companies) {
    const url = `${this.backendUrlV2}marketplace/web/references/upsell?company_uids=${companies}`
    const config = {}

    return this.fetcher.get(url, config).then(response => ({
      references: response.references,
    }))
  }

  addImage(uid, image) {
    const url = `${this.backendUrl}references/${uid}/images`
    return this.fetcher.post(url, { image }).then(response => response.image)
  }
  deleteImage(uid, imageUid) {
    const url = `${this.backendUrl}references/${uid}/images/${imageUid}`
    return this.fetcher.delete(url).then(response => response.success)
  }

  _formatFiltersForQuery(filters) {
    let params = {}
    if (filters && filters.length > 0) {
      const brandsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'BRAND') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (brandsFilter.length > 0) params['search[brand_uids]'] = brandsFilter.join()

      const categoriesFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'CATEGORY') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (categoriesFilter.length > 0) {
        params['search[category_uids]'] = categoriesFilter.join()
      }

      const merchantsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'MERCHANT') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (merchantsFilter.length > 0) {
        params['search[merchant_uids]'] = merchantsFilter.join()
      }

      const merchantsUidFilter = filters.find(f => f.type === 'MERCHANT_UID')
      if (merchantsUidFilter) {
        params['merchant_uid'] = merchantsUidFilter.value
      }

      const localityFilter = filters.find(f => f.type === 'LOCALITY')
      let coordinatesFilter = null
      if (localityFilter) {
        coordinatesFilter = localityFilter.value?.split('+')[1]?.split(',')
        if (coordinatesFilter) {
          ;[params['search[location][lat]'], params['search[location][lon]']] = coordinatesFilter
        }
      }

      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) {
        if (this?.runtimeConfig?.public?.SEARCH_ENDPOINT_V2 === '1') {
          params['references[prefix_name]'] = keywordsFilter.value
        } else {
          params['search[prefix_name]'] = keywordsFilter.value
        }
      }

      const deliveryFilter = filters.find(f => f.type === 'DELIVERY')
      if (deliveryFilter) params['search[has_delivery]'] = deliveryFilter.value

      const pickupFilter = filters.find(f => f.type === 'PICKUP')
      if (pickupFilter) params['search[has_pick_up]'] = pickupFilter.value

      const distanceFilter = filters.find(f => f.type === 'DISTANCE')
      if (distanceFilter) params['search[location][distance]'] = distanceFilter.value

      const minPriceFilter = filters.find(f => f.type === 'MIN_PRICE')
      if (minPriceFilter) params['search[min_price]'] = minPriceFilter.value

      const maxPriceFilter = filters.find(f => f.type === 'MAX_PRICE')
      if (maxPriceFilter) params['search[max_price]'] = maxPriceFilter.value

      const orderByFilter = filters.find(f => f.type === 'ORDER_BY')
      if (orderByFilter) params['search[order_by]'] = decodeURI(orderByFilter.value)

      const withVATFilter = filters.find(f => f.type === 'WITH_VAT')
      if (withVATFilter) params['search[with_vat]'] = withVATFilter.value

      const isFeaturedFilter = filters.find(f => f.type === 'IS_FEATURED')
      if (isFeaturedFilter) params.is_featured = isFeaturedFilter.value

      let freshnessFilter = filters.filter(f => f.type === 'FRESHNESS')
      if (freshnessFilter.length > 0) params.mkp_freshness = Math.max(...freshnessFilter.map(f => f.value))

      let underBestDealsFilter = filters.filter(f => f.type === 'BESTDEALS' && f.query === 'under_best_deal')
      if (underBestDealsFilter.length > 0)
        params.under_best_deal = Math.min(...underBestDealsFilter.map(f => f.value?.split('_')[0]))

      let bestDealsFilter = filters.filter(f => f.type === 'BESTDEALS' && f.query === 'best_deal')
      if (bestDealsFilter.length > 0) params.best_deal = Math.min(...bestDealsFilter.map(f => f.value?.split('_')[0]))

      const refsUidFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'REFS_UID') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (refsUidFilter.length > 0) params['refs_uid'] = refsUidFilter

      const tagsFilters = filters.filter(f => f.type === 'TAGS')
      if (tagsFilters.length) {
        tagsFilters.forEach(tag => {
          params.tags = tag.value
        })
      }

      const merchantTagsFilters = filters.filter(f => f.type === 'MERCHANT_TAGS')
      if (merchantTagsFilters.length) {
        merchantTagsFilters.forEach(tag => {
          params['merchant_tags'] = tag.value
        })
      }
    }
    return params
  }
}
