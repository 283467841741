import { useI18nStore } from '@/stores/i18nStore'

export default defineNuxtRouteMiddleware(to => {
  const { lang, ...otherQueryParams } = to.query
  const i18nStore = useI18nStore()

  // Yuri recommandation before nuxt 3 : in multi lang country only have urls with lang params
  if (i18nStore.country.availableLanguages.length > 1 && i18nStore.locale && !lang) {
    return navigateTo({ path: to.path, query: { lang: i18nStore.locale, ...otherQueryParams } }, { redirectCode: 301 })
  }

  if (!lang) return

  // 1. In case of duplicated param lang (lang=nl,nl,nl&lang=nl) when Google bot crawls
  // if there is 2 lang params, keep only the first
  if (Array.isArray(lang) && lang.length > 1) {
    const cleanedLang = lang[0]
    const cleanedQueryParams = { lang: cleanedLang, ...otherQueryParams }
    return navigateTo({ path: to.path, query: cleanedQueryParams }, { redirectCode: 301 })
  }
  // Keep lang first input in case of lang=nl,nl,nl
  if (typeof lang === 'string' && lang.includes(',')) {
    const cleanedLang = lang?.split(',')[0]
    const cleanedQueryParams = { lang: cleanedLang, ...otherQueryParams }
    return navigateTo({ path: to.path, query: cleanedQueryParams }, { redirectCode: 301 })
  }

  // 2. In case of lang=fr param on stock-pro.fr (google bots behaviour)
  if (i18nStore.country.code === to.query.lang) {
    const cleanedQueryParams = { ...otherQueryParams }
    return navigateTo({ path: to.path, query: cleanedQueryParams }, { redirectCode: 301 })
  }
})
