<template>
  <div class="sb-additional-services-section" :style="`--mainAdditionalSectionBg: ${bg_color}`">
    <div class="sb-additional-services-section-container">
      <div class="sb-additional-services-section-container-title">
        <span>{{ title || $t('proArea.services.essentialsSimple') }}</span>
        <div class="sb-additional-services-section-container-subtitle">
          <span>{{ subtitle || $t('proArea.services.essentialsInfo') }}</span>
        </div>
      </div>

      <div class="sb-additional-services-section-container-services-list">
        <nuxt-link
          v-for="serviceItem in services"
          :key="serviceItem.uuid"
          :to="`/services/additional#${serviceItem.full_slug?.split('/').pop()}`"
          class="sb-additional-services-section-container-services-list-child"
          :style="`--cardBg: ${card_bg_color}`"
        >
          <template v-if="serviceItem.content">
            <div
              v-if="serviceItem.content?.icon?.filename"
              class="sb-additional-services-section-container-services-list-child-logo"
            >
              <ImageLazy :src="serviceItem.content.icon.filename" alt="service logo" class-variant="sb-icon" />
            </div>

            <div
              v-if="serviceItem.content.title"
              class="sb-additional-services-section-container-services-list-child-title"
            >
              {{ serviceItem.content.title }}
            </div>

            <div
              v-if="serviceItem.content.short_description"
              class="sb-additional-services-section-container-services-list-child-description hide-xs"
            >
              {{ serviceItem.content.short_description }}
            </div>

            <div class="sb-additional-services-section-container-services-list-child-link hide-xs">
              <nuxt-link v-if="!anchor_links" to="/contact">
                {{ $t('actions.beContacted') }}
              </nuxt-link>
              <a v-else :href="`#${serviceItem.slug}`">
                {{ $t('actions.knowMore') }}
              </a>
            </div>
          </template>
        </nuxt-link>

        <nuxt-link
          v-if="!hide_show_more"
          to="/services/additional"
          class="sb-additional-services-section-container-services-list-child u-bg-white hide-xs"
        >
          <div v-if="service.logo" class="sb-additional-services-section-container-services-list-child-logo">
            <ImageLazy :src="service.logo" alt="service logo" />
          </div>

          <div class="sb-additional-services-section-container-services-list-child-title">
            {{ service.title }}
          </div>

          <div class="sb-additional-services-section-container-services-list-child-description hide-xs">
            {{ service.description }}
          </div>

          <div class="sb-additional-services-section-container-services-list-child-link hide-xs">
            {{ $t('actions.beContacted') }}
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SbAdditionalServicesSection',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: '#FFF' },
    // eslint-disable-next-line vue/prop-name-casing
    card_bg_color: { type: String, default: '#E9EDF1' },
    // eslint-disable-next-line vue/prop-name-casing
    hide_show_more: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    anchor_links: { type: Boolean, default: false },
  },
  data() {
    return {
      service: {
        title: 'Services complémentaires',
        description: 'Réemployez vos stocks dormants. Optimisez la gestion de vos dépôts.',
        logo: 'https://a.storyblok.com/f/70238/48x48/78af6dde07/stock-icon.png',
      },
    }
  },
  computed: {
    ...mapGetters('proArea', ['getStoryBySlug', 'getServicesParentList', 'getServicesChildList']),

    services() {
      const services = []

      this.getServicesParentList.forEach(sp => {
        this.getServicesChildList(sp).forEach(sc => {
          services.push(this.getStoryBySlug(sc))
        })
      })

      return services
    },
  },
  methods: {
    richText(text) {
      if (text) {
        return renderRichText(text)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.sb-additional-services-section {
  padding: $spacing-xl;
  background-color: var(--mainAdditionalSectionBg);

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-container {
    max-width: 1000px;
    margin: 0 auto;

    &-title {
      text-align: center;
      font-weight: 800;
      font-size: pxToRem(30px);
      padding-bottom: $spacing-xl;
    }

    &-subtitle {
      font-weight: 400;
      font-size: pxToRem(15px);
    }

    &-services {
      &-list {
        display: flex;
        gap: $spacing-md;
        flex-wrap: wrap;

        @include mq($mq-xs) {
          flex-direction: column;
          gap: 0;
          border-radius: 5px;
          overflow: hidden;
        }

        &-child {
          display: flex;
          flex-direction: column;
          background-color: var(--cardBg);
          padding: $spacing-md;
          border-radius: 5px;
          width: 235px;
          text-decoration: none;

          @include mq($mq-md) {
            width: 151px;
            padding-top: $spacing-sm;
            padding-bottom: $spacing-sm;
          }

          @include mq($mq-xs) {
            align-items: center;
            flex-direction: row;
            gap: $spacing-sm;
            border-radius: 0;
            width: 100%;
          }

          &-logo {
            width: 48px;
            height: 48px;
            margin-bottom: $spacing-sm;

            @include mq($mq-xs) {
              width: 28px;
              height: 28px;
              margin-bottom: 0;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          &-title {
            color: var(--dark-blue);
            font-weight: 800;
            font-size: pxToRem(15px);
            padding-bottom: $spacing-sm;

            @include mq($mq-xs) {
              font-weight: 400;
              padding-bottom: 0;
            }
          }

          &-description {
            font-weight: 400;
            font-size: pxToRem(15px);
            color: var(--steel-grey);
            padding-bottom: $spacing-md;
            flex-grow: 1;
          }

          &-link,
          &-link a {
            font-weight: 800;
            font-size: pxToRem(12px);
            color: var(--primary);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
