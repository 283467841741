import { useClubStore } from '@/stores/club'
import { useStore } from 'vuex'

export default defineNuxtPlugin(async ctx => {
  // console.log('nuxtServerInit')
  const config = useRuntimeConfig()
  const clubStore = useClubStore()
  let host

  if (import.meta.server) {
    host = ctx.ssrContext?.event.node.req.headers.host
  } else if (import.meta.client) {
    host = window.location.host
  }
  const subpaths = host?.split('.') || []
  if (subpaths.length > 2 && subpaths[0] === 'pro') {
    const store = useStore()
    store.commit('proArea/setIsProDomain', subpaths[0])
  }
  if (
    subpaths.length > 2 &&
    !['www', 'pro', 'intl', 'v3', 'staging', config.public.ENV_NAME?.toLowerCase()].includes(subpaths[0])
  ) {
    clubStore.setClubDomain(subpaths[0])
    // console.log('getClub', subpaths[0])
    clubStore.getClub()
  }
})
