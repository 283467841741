<template>
  <div v-if="title" :style="bg_color ? `background-color: ${bg_color}` : ''">
    <div class="inner-container fad">
      <h1
        class="landing-download__title"
        :class="{ 'text-center': text_center }"
        :style="text_color ? `color: ${text_color} !important` : ''"
      >
        {{ title }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbTitle',
  props: {
    title: { type: String, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    text_center: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: null },
    // eslint-disable-next-line vue/prop-name-casing
    text_color: { type: String, default: null },
  },
}
</script>

<style lang="scss">
.landing-download {
  &__title {
    padding-bottom: $spacing-sm;
    padding-top: $spacing-xl;

    @include mq($mq-xs) {
      padding-top: $spacing-md;
    }
  }
}
</style>
