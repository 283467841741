import mitt from 'mitt'

export default defineNuxtPlugin(() => {
  // Provide the bus globally
  const bus = mitt()

  return {
    provide: {
      bus,
    },
  }
})
