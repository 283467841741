import Repository from './repository'

export default class ClubRepository extends Repository {
  async get(mapping = this.mapper.currentClubMapping) {
    const url = `${this.backendUrl}company_groups/current`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => {
      if (!response.success) {
        throw response.message
      }
      return response.company_group
    })
  }
}
