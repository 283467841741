<template>
  <div class="sb-cta-groups-wrapper" :style="`--ctaBgColor: ${bg_color}`">
    <div
      v-if="buttons"
      class="sb-cta-groups"
      :class="{ 'u-p-x-lg': has_padding_vertical, 'sb-cta-groups-unique': buttons.length === 1 && !button_center }"
    >
      <SbCta
        v-for="button in buttons"
        :key="button._uid"
        :button="button"
        :class="classVariant"
        :button-length="buttons.length"
        class="sb-cta-groups-item"
      >
        <ImageLazy
          v-if="button.icon && button.icon.filename"
          :src="`${button.icon.filename}`"
          :alt="`${button.title}`"
          :title="`${button.title}`"
          width="40px"
          height="40px"
        />
      </SbCta>
    </div>
  </div>
</template>

<script>
import SbCta from '@/components/Storyblok/SbCta'

export default {
  name: 'SbCtaGroup',
  components: {
    SbCta,
  },
  props: {
    buttons: { type: Array, default: undefined },
    classVariant: { type: null, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    has_padding_vertical: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    button_center: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
.sb-cta-groups-wrapper {
  background-color: var(--ctaBgColor);
  width: 100%;
  @include mq($mq-xs) {
    padding: 0 $spacing-md;
  }
}
.sb-cta-groups {
  max-width: calc(var(--container-width) / 3 * 2);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: $spacing-md 0;
  gap: $spacing-md;
  @include mq($mq-xs) {
    flex-direction: column;
  }
  &-item {
    width: 280px !important;
    @include mq($mq-xs) {
      width: 100% !important;
    }
    .full-width {
      flex: 1;
    }
  }
  &-unique {
    max-width: calc(var(--container-width) / 3);
    flex: 1;
    justify-content: flex-start !important;
    margin: 0;
  }
}

.blog-article-content-text-wrapper-description {
  .sb-cta-groups {
    flex-wrap: wrap;
    max-width: 100%;
    .sb-cta-groups-item {
      width: fit-content !important;
      max-width: 100%;
      flex: auto;
      .btn-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 450px;
      }
    }
  }
  .sb-cta-groups-unique {
    flex-wrap: wrap;
    max-width: 100%;
    .sb-cta-groups-item {
      width: fit-content !important;
      max-width: 100%;
      flex: initial;
      .btn-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
