export const state = () => ({
  selectedStock: {},
})

export const mutations = {
  setSelectedStock(state, selectedStock) {
    state.selectedStock = selectedStock
  },
}

export default {
  state,
  mutations,
  namespaced: true,
}
