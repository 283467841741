<template>
  <div class="sb-table">
    <h4>{{ title }}</h4>
    <table :style="bgColor">
      <thead>
        <tr>
          <th v-for="th in table.thead" :key="th._uid" :style="thColor">{{ th.value }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tr in table.tbody" :key="tr._uid">
          <td v-for="td in tr.body" :key="td._uid">{{ td.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Mobile from '@/mixins/mobile-mixin'

export default {
  name: 'SbTable',
  mixins: [Mobile],
  props: {
    title: { type: [Object, String], default: undefined },
    table: { type: Object, default: () => {} },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    th_color: { type: String, default: undefined },
  },
  computed: {
    bgColor() {
      return this.bg_color ? 'background-color:' + this.bg_color : false
    },
    thColor() {
      return this.th_color ? 'color:' + this.th_color : false
    },
  },
}
</script>

<style lang="scss">
.sb-table {
  table {
    border-collapse: collapse;
    border-radius: $spacing-xs;
    overflow: hidden;
    td,
    th {
      border: 1px solid var(--bg-grey);
      padding: $spacing-xs $spacing-sm;
    }
    th {
      font-weight: 800;
    }
  }
}
</style>
