import { defineStore } from 'pinia'

import { useCurrenti18n } from '@/composables/useCurrenti18n'

const state = () => ({
  tld: null,
  locale: null,
  country: null,
})

const getters = {
  langCode:
    state =>
    (min = false, revert = false) => {
      let currentLocale = state.locale
      let location = state.tld
      if (min && currentLocale?.toLowerCase() === location?.toLowerCase()) {
        return currentLocale
      }
      if (revert) {
        return location?.toLowerCase() + '-' + currentLocale?.toLowerCase()
      }
      return currentLocale?.toLowerCase() + '-' + location?.toLowerCase()
    },
}

const actions = {
  async init() {
    const currenti18n = useCurrenti18n()
    // console.log('init i18nStore')
    if (!this.tld) {
      const tld = currenti18n.currentLocation()
      // console.log('i18nStore tld', tld)
      this.tld = tld
    }

    if (!this.locale) {
      const locale = currenti18n.currentLocale()
      // console.log('i18nStore locale', locale)
      this.locale = locale
      // ctx.i18n.locale = locale
    }

    if (!this.country) {
      const country = currenti18n.currentCountry()
      // console.log('i18nStore country', country)
      this.country = country
    }
  },
}

export const useI18nStore = defineStore('i18nStore', {
  state,
  getters,
  actions,
})
