import { useI18nStore } from '@/stores/i18nStore'

export function useCurrenti18n() {
  const i18nStore = useI18nStore()
  const { countries } = useCountries()
  const nuxtApp = useNuxtApp()
  const currentLocation = () => {
    let tld = 'FR'

    if (i18nStore?.tld) {
      tld = i18nStore.tld.toUpperCase()
    } else if (import.meta.client && window?.location?.hostname?.split('.').pop()) {
      tld = window?.location?.hostname?.split('.')?.pop()?.toUpperCase() || ''
    } else if (import.meta.server) {
      if (nuxtApp?.ssrContext?.event?.node?.req?.headers?.host) {
        const host = nuxtApp.ssrContext.event.node.req.headers.host
        tld = host?.split('.').pop()?.toUpperCase() || ''
      }
    }

    if (tld.includes(':')) {
      tld = tld?.split(':').shift() || tld
    }
    if (['COM', 'LOCALHOST'].includes(tld)) {
      tld = 'FR'
    }

    if (i18nStore) {
      i18nStore.tld = tld
    }

    return tld
  }

  const currentCountry = () => {
    const tld = currentLocation()
    return countries.find(locale => locale.code.toUpperCase() === tld)
  }

  const currentLangCode = (min = false, revert = false) => {
    const locale = currentLocale()
    const location = currentLocation()

    if (min && locale.toLowerCase() === location.toLowerCase()) {
      return locale
    }
    if (revert) {
      return `${location.toLowerCase()}-${locale.toLowerCase()}`
    }
    return `${locale.toLowerCase()}-${location.toLowerCase()}`
  }

  const currentLocale = () => {
    let locale = 'fr'
    let country = currentCountry()
    const langCookie = useCookie('x-lang', { maxAge: 60 * 60 * 24 * 365, domain: `.${country?.host}` })

    if (nuxtApp?.route?.query?.lang) {
      locale = Array.isArray(nuxtApp?.route.query.lang) ? nuxtApp?.route.query.lang[0] : nuxtApp?.route.query.lang
    } else if (langCookie?.value) {
      locale = langCookie?.value
    } else if (i18nStore?.locale) {
      locale = i18nStore.locale
    } else if (import.meta.client && navigator?.language?.split('-').length > 1) {
      locale = navigator.language?.split('-')[1].toLowerCase()
    } else if (import.meta.client && navigator?.language) {
      locale = navigator.language.toLowerCase()
    } else if (country) {
      locale = country.availableLanguages[0]
    }
    if (!langCookie || !langCookie.value) {
      langCookie.value = locale
    }

    return country.availableLanguages.includes(locale) ? locale : country.availableLanguages[0]
  }

  return {
    currentLocale,
    currentLangCode,
    currentLocation,
    currentCountry,
  }
}
