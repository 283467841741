<template>
  <div class="agec">
    <!-- TODO : this component could probably be merged with LdNetworkSection component -->
    <div class="inner-container fad">
      <div class="agec__flex">
        <div class="agec__txt">
          <h2 class="agec__title">
            {{ title }}
          </h2>
          <p class="landing-download__subtitle" v-html="richText" />
          <main-button
            :label="cta"
            link="https://calendly.com/stephane-stockpro/loi-agec"
            tag="a"
            target="_blank"
            icon-position="RIGHT"
            class="agec__btn"
            class-variant="btn-white-blue full-width-md"
          >
            <icon-info />
          </main-button>
        </div>
        <div v-if="image?.filename" class="agec__img">
          <ImageLazy :src="image.filename" :alt="image.alt" :title="image.title" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton/MainButton'
import IconInfo from '@/icons/IconInfo'

export default {
  name: 'SbAgecSection',
  components: {
    MainButton,
    IconInfo,
  },
  props: {
    image: { type: Object, required: true },
    cta: { type: String, required: true },
    title: { type: String, required: true },
    text: { type: Object, required: true },
  },
  computed: {
    richText() {
      if (this.text) {
        return renderRichText(this.text)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.agec {
  background-color: var(--landing-color-secondary);
  margin: 60px 0;

  &__title {
    margin-bottom: 20px;
    color: var(--white);
  }

  p {
    color: var(--white);
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0;

    @include mq($mq-sm) {
      flex-direction: column-reverse;
    }

    & > * {
      @include mqmin($mq-sm) {
        flex: 0 0 calc(50% - 20px / 2);

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  &__img {
    @include mq($mq-sm) {
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
</style>
