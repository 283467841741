import { ref, computed } from 'vue'

import { useReference } from '@/composables/useReference'
import { useCategory } from '@/composables/useCategory'
import truncate from 'lodash.truncate'

import { useClubStore } from '@/stores/club'
import { useConfigStore } from '@/stores/config'
import { useQuery } from '@/composables/useQuery'
import { useCurrentLocale } from '@/composables/useCurrentLocale'

import { useStore } from 'vuex'

export function useHeadMeta() {
  const { $router, $i18n } = useNuxtApp()
  const { country, locale } = useCurrentLocale()

  const route = useRoute()
  const config = useRuntimeConfig()
  const localePath = useLocalePath()
  const store = useStore()

  const configStore = useConfigStore()
  const clubStore = useClubStore()

  const { countries } = useCountries()
  const defaultTitle = computed(() => country?.metaTitle?.[locale])
  const defaultDescription = computed(() => country?.metaDescription?.[locale])
  const pageData = configStore.getPageData

  const customScript = ref([])
  const reference = ref({})
  const references = ref([])
  const brand = ref({})
  const merchant = ref({})
  const category = ref({})
  const dynamicMeta = ref({})
  const article = ref({})
  const articles = ref([])
  const articleContent = ref(null)
  const totalPages = ref(0)
  const blogHome = ref(false)
  const noIndex = ref(false)

  const query = import.meta.client
    ? window?.location?.search?.replace('?', '')
    : Object.keys(route?.query || {})
        .map(key => `${key}=${route.query[key]}`)
        .join('&')

  const currentPage =
    query
      ?.split('&')
      .find(param => param.startsWith('page='))
      ?.split('=')[1] || null

  const langParam =
    query
      ?.split('&')
      .find(param => param.startsWith('lang='))
      ?.split('=')[1] || null

  const getTranslatedPath = lang => {
    const path = import.meta.client ? window?.location?.pathname : route?.path || ''
    const previousSlug = pageData?.slug
    const newSlug = pageData?.slugs?.[lang]
    return previousSlug && newSlug ? path.replace(previousSlug, newSlug) : path
  }

  const getCurrentUrl = () => {
    const path = import.meta.client ? window?.location?.pathname : route?.path || ''
    return `https://www.${country.host}${path}${currentPage > 1 || langParam ? `?${query}` : ''}`
  }

  const getHrefLang = () => {
    // get TLD and PATH
    const _hreflang = []

    const path = import.meta.client ? window?.location?.pathname : route?.path || ''

    const translatedSlugs = ['/category/', '/product/']

    const excludeUrls = ['/blog/', '/press/']

    const isProductPage = path.includes('/product/')

    const isCategoryPage =
      path.includes('/category/') &&
      !path.includes('category/brand') &&
      !path.includes('category/best-deals') &&
      !path.includes('category/best-sellers') &&
      !path.includes('category/green-everyday') &&
      !path.includes('category/new-products') &&
      !path.includes('category/rooms') &&
      !path.includes('category/seasonal-selections') &&
      !path.includes('category/special-discounts')

    const isBrandPage = path.includes('/brand/')
    const isMerchantPage = path.includes('/merchant/')

    function referenceHasProductsInCountry(countrySelected) {
      if (reference?.value?.public_stock_countries?.length) {
        return reference?.value?.public_stock_countries?.find(x => x === countrySelected.code)
      }
      return true
    }

    function categoryHasProductsInCountry(countrySelected) {
      if (
        category?.value?.stockAvailable?.[countrySelected.code] !== null ||
        category?.value?.stockAvailable?.[countrySelected.code] !== undefined
      ) {
        return category?.value?.stockAvailable?.[countrySelected.code] > 0
      }
      return true
    }

    function brandHasProductsInCountry(countrySelected) {
      if (
        brand.value?.offersCountByCountry?.[countrySelected.code] !== null ||
        brand.value?.offersCountByCountry?.[countrySelected.code] !== undefined
      ) {
        return brand.value?.offersCountByCountry?.[countrySelected.code] > 0
      }
      return true
    }

    function merchantHasProductsInCountry(countrySelected) {
      if (
        merchant.value?.offers_count_by_country?.[countrySelected.code] !== null ||
        merchant.value?.offers_count_by_country?.[countrySelected.code] !== undefined
      ) {
        return merchant.value?.offers_count_by_country?.[countrySelected.code] > 0
      }
      return true
    }

    function addHrefLang(countrySelected, lang, currentPath, pathLang = null) {
      if (
        (isProductPage && !referenceHasProductsInCountry(countrySelected)) ||
        (isCategoryPage && !categoryHasProductsInCountry(countrySelected)) ||
        (isBrandPage && !brandHasProductsInCountry(countrySelected)) ||
        (isMerchantPage && !merchantHasProductsInCountry(countrySelected))
      ) {
        return
      }
      _hreflang.push({
        key: `alternate-hreflang${lang ? `-${lang}` : ''}`,
        rel: 'alternate',
        hreflang: lang,
        href: `https://www.${countrySelected.host}${currentPath}${pathLang ? `?lang=${pathLang}` : ''}${
          currentPage > 1 ? `${pathLang ? '&' : '?'}${query}` : ''
        }`,
      })
    }

    // for each country, create an hreflang and handle multiples langs
    function getHrefLangForCountry(c) {
      if (c.availableLanguages?.length > 1) {
        for (const lang of c.availableLanguages) {
          const currentPath =
            lang !== locale && translatedSlugs.some(x => path.match(x)) ? getTranslatedPath(lang) : path
          addHrefLang(c, `${c.code}-${lang?.toUpperCase()}`, currentPath, lang)
        }
      } else {
        const currentPath = getTranslatedPath(c.availableLanguages[0])
        addHrefLang(c, c.code, currentPath)
      }
    }
    // define urls to exclude to generate an hreflang for other countries
    if (!excludeUrls.some(x => path.match(x))) {
      for (const c of countries) {
        getHrefLangForCountry(c)
      }
    } else {
      getHrefLangForCountry(country)
    }

    if (isProductPage && !reference?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('reference is not defined')
    }
    if (isCategoryPage && !category?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('category is not defined')
    }
    if (isBrandPage && !brand?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('category is not defined')
    }
    if (isMerchantPage && !merchant?.value?.uid && config.public.NODE_ENV !== 'production') {
      console.log('category is not defined')
    }
    return _hreflang
  }

  const getCanonical = () => {
    const path = import.meta.client ? window?.location?.pathname : route?.path || ''
    // create canonical
    const canonical = {
      key: 'canonical',
      rel: 'canonical',
      href: `https://www.${country.host}${path}${currentPage > 1 || langParam ? `?${query}` : ''}`,
    }
    return canonical
  }

  const hreflang = computed(() => getHrefLang() || [])

  const canonical = computed(() => getCanonical() || [])
  const customKey = ref(null)
  const customTarget = ref(null)

  const setTitle = () => {
    let title =
      dynamicMeta?.value?.title ??
      (customKey.value && $i18n.te(`meta.title.${customKey.value}`)
        ? $i18n.t(`meta.title.${customKey.value}`, {
            [customKey.value]: customTarget.value
              ? customTarget.value
              : clubStore.isClub
                ? truncate(clubStore.name, { length: 70 })
                : 'StockPro',
          })
        : null) ??
      defaultTitle.value
    if (!title?.includes(' | StockPro')) {
      title = `${title} | StockPro`
    }
    return title
  }

  const setDescription = () => {
    return (
      dynamicMeta?.value?.description ??
      (customKey.value && $i18n?.te(`meta.description.${customKey.value}`)
        ? $i18n?.t(`meta.description.${customKey.value}`, {
            [customKey.value]: truncate(customTarget.value, { length: 70 }),
          })
        : null) ??
      defaultDescription.value
    )
  }

  const defaultHead = () => {
    const { status } = useAuth()
    const title = setTitle()
    const description = setDescription()
    const scripts = [
      ...customScript.value,
      {
        hid: 'newrelic',
        src: `/newrelic-${config.public.ENV_NAME}.js`,
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'probance',
        src: '//t4.my-probance.one/webtrax/rsc/stockpro.js',
        async: true,
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
    ]
    if (status.value === 'authenticated' && country?.code === 'fr' && !clubStore.isClub) {
      scripts.push({
        hid: 'mcjs',
        innerHTML: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/49648c4b7d22f482da0ee9755/ea1440b5ec04d665666c7e22f.js");`,
        type: 'text/javascript',
        async: true,
        defer: true,
        charset: 'utf-8',
      })
    }
    const meta = [{ name: 'facebook-domain-verification', content: config.public.FACEBOOK_VERIFICATION }]
    if (noIndex.value) {
      meta.push({ key: 'robots', hid: 'robots', name: 'robots', content: 'noindex' })
    }
    const links = [
      {
        key: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: clubStore.isClub ? clubStore.clubConfig?.favicon : '/favicon.ico',
      },
      ...hreflang.value,
      canonical.value,
    ]
    if (store?.getters?.['proArea/isProDomain']) {
      links.map(x => {
        x.href = x.href.replace('www', 'pro')
        return x
      })
    }
    return {
      htmlAttrs: {
        lang: locale || 'fr',
      },
      __dangerouslyDisableSanitizers: ['script'],
      title,
      description,
      meta,
      link: links,
      script: scripts,
    }
  }
  const defaultSeo = () => {
    const title = setTitle()
    const description = setDescription()
    return {
      charset: 'utf-8',
      viewport: 'width=device-width, initial-scale=1',
      ogType: 'website',
      title,
      ogTitle: title,
      description,
      ogDescription: description,
      ogImage: dynamicMeta?.value?.ogImage || 'https://d3brsr9pdomwt0.cloudfront.net/social/web_banner.jpg',
      googleSiteVerification: config.public.GOOGLE_VERIFICATION,
    }
  }

  const paginationHead = () => {
    if (!references?.value?.length) console.log('no references')
    if (!totalPages?.value) console.log('no totalPage')
    let links = []
    const { query } = route
    const { getPage } = useQuery()
    const currentPage = getPage()

    if (currentPage > 1) {
      // link = [{ rel: 'canonical', href: `${localePath({ name, query, params })}` }]
    }
    if (currentPage > 1 && references?.value?.length === 0) {
      query.page = totalPages.value
      links.push({ key: 'prev', rel: 'prev', href: getCurrentUrl() })
    } else {
      if (currentPage > 1) {
        query.page = currentPage - 1
        links.push({ key: 'prev', rel: 'prev', href: getCurrentUrl() })
      } else {
        links.push({ key: 'prev', rel: 'prev', href: '' })
      }
      if (currentPage < totalPages.value) {
        query.page = currentPage + 1
        links.push({ key: 'next', rel: 'next', href: getCurrentUrl() })
      } else {
        links.push({ key: 'prev', rel: 'prev', href: '' })
      }
      query.page = currentPage
    }
    const d = defaultHead()
    return {
      head: {
        ...d,
        link: () => [...d.link, ...links],
      },
      meta: {
        ...defaultSeo(),
      },
      // TODO add schema for pagination head
    }
  }

  const homeHead = () => {
    customKey.value = 'home'
    const d = defaultHead()
    return {
      head: {
        ...d,
      },
      meta: {
        ...defaultSeo(),
      },
      schema: [corporationSchema()],
    }
  }

  const productHead = () => {
    if (!reference?.value) console.log('no reference')
    const meta = {
      ...defaultSeo(),
      title: `${reference?.value?.name} | StockPro`,
      ogUrl: `${config.public.PRODUCTION_URL}${route.path.substr(1)}`,
      ogTitle: `${reference?.value?.name} | StockPro`,
      ogType: 'article',
      ogDescription: $i18n.t('meta.description.product', {
        product: truncate(reference?.value?.name, { length: 70 }),
      }),
      description: $i18n.t('meta.description.product', {
        product: truncate(reference?.value?.name, { length: 70 }),
      }),
    }

    if (reference?.value?.images && reference?.value?.images.length > 0) {
      meta.ogImageSecureUrl = reference?.value?.thumbnailUrl
    }
    customKey.value = 'home'
    const d = defaultHead()
    return {
      head: {
        ...d,
        title: `${reference?.value?.name} | StockPro`,
      },
      meta,
      schema: [productBreadcrumbSchema(), productSchema()],
    }
  }

  const categoryHead = () => {
    if (!category?.value) console.log('no category')
    if (!totalPages?.value) console.log('no totalPage')
    let links = []
    const { query } = route
    const { getPage } = useQuery()
    const currentPage = getPage()
    if (!category?.value?.hasChildren) {
      if (currentPage > 1) {
        // link = [{ rel: 'canonical', href: `${localePath({ name, query, params })}` }]
      }
      if (currentPage > 1 && references?.value?.length === 0) {
        query.page = totalPages.value
        links.push({ key: 'prev', rel: 'prev', href: getCurrentUrl() })
      } else {
        if (currentPage > 1) {
          query.page = currentPage - 1
          links.push({ key: 'prev', rel: 'prev', href: getCurrentUrl() })
        }
        if (currentPage < totalPages.value) {
          query.page = currentPage + 1
          links.push({ key: 'next', rel: 'next', href: getCurrentUrl() })
        }
        query.page = currentPage
      }
    }
    if (!customKey.value) {
      customKey.value = 'home'
    }
    if (!customTarget.value) {
      customTarget.value = category?.value.name
    }
    const d = defaultHead()
    return {
      head: {
        ...d,
        link: [...d.link, ...links],
        title: dynamicMeta?.value?.title || `${truncate(category?.value.name, { length: 70 })} | StockPro`,
      },
      meta: {
        ...defaultSeo(),
        title: dynamicMeta?.value?.title || `${truncate(category?.value.name, { length: 70 })} | StockPro`,
      },
      schema: [categoryBreadcrumbSchema()],
    }
  }

  const blogHead = () => {
    const title = `${
      article?.value?.content?.meta_title ||
      article?.value?.meta_title ||
      article?.value?.content?.title ||
      article?.value?.title ||
      article?.value?.content?.name ||
      article?.value?.name ||
      dynamicMeta?.value?.title ||
      $i18n.t('blog.sectionBtnLabel')
    } | StockPro`
    const description = truncate(
      article?.value?.description ||
        article?.value?.shortDescription ||
        article?.value?.content?.shortDescription ||
        dynamicMeta?.value?.description ||
        article?.value?.content?.description,
      { length: 160 }
    )
    const meta = {
      ...defaultSeo(),
      title,
      ogTitle: title,
      ogUrl: `${config.public.PRODUCTION_URL}${$router.currentRoute.value.path.substr(1)}`,
      ogType: 'article',
      description,
      ogDescription: description,
    }
    customKey.value = 'blog'
    const d = defaultHead()
    return {
      head: {
        ...d,
        title,
        description,
        link: [
          ...d.link,
          {
            key: 'preload',
            rel: 'preload',
            href: `${
              article?.value?.content?.mainImage?.filename ||
              article?.value?.mainImage?.filename ||
              article?.value?.content?.mainImage?.url ||
              article?.value?.mainImage?.url ||
              article?.value?.content?.mainImage ||
              article?.value?.mainImage
            }`,
            as: 'image',
          },
        ],
      },
      meta,
      schema: [blogArticleSchema()],
    }
  }

  const productSchema = () => {
    const ref = useReference(reference?.value || {})
    const schema = {
      '@context': 'https://schema.org/',
      '@graph': [
        {
          '@type': 'Product',
          name: ref.name,
          image: ref.mainImageUrl,
          description: ref.description,
          brand: ref.brandName,
        },
      ],
    }
    if (ref.ean) {
      schema.sku = ref.ean
    } else if (ref.refCo) {
      schema.sku = ref.refCo
    }
    schema.offers = {
      '@type': 'Offer',
      url: `${config.public.PRODUCTION_URL}${route.path.substr(1)}`,
      availability: ref.hasStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock"',
    }
    if (schema.sku) {
      schema.offers.sku = schema.ean
    }
    if (ref.hasStock && ref.bestStock) {
      schema.offers.priceCurrency = 'EUR'
      schema.offers.price = ref.bestStock?.price_vat
      schema.offers.eligibleQuantity = ref.bestStock?.quantity
    }
    return schema
  }

  const productBreadcrumbSchema = () => {
    const items = useCategory(reference?.value?.category).breadcrumbPath.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'WebPage',
        '@id': `${config.public.PRODUCTION_URL}${localePath({
          name: 'category-slug',
          params: { slug: item.slug },
        }).substr(1)}`,
        url: `${config.public.PRODUCTION_URL}${localePath({
          name: 'category-slug',
          params: { slug: item.slug },
        }).substr(1)}`,
        name: item.name,
      },
    }))
    items.push({
      '@type': 'ListItem',
      position: items.length + 1,
      item: {
        '@type': 'WebPage',
        '@id': `${config.public.PRODUCTION_URL}${route.path.substr(1)}`,
        url: `${config.public.PRODUCTION_URL}${route.path.substr(1)}`,
        name: reference.name,
      },
    })
    return {
      '@context': 'http://schema.org',
      '@graph': [
        {
          '@type': 'BreadcrumbList',
          itemListElement: items,
        },
      ],
    }
  }

  const categoryBreadcrumbSchema = () => {
    const items = category?.value?.breadcrumbPath?.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'WebPage',
        '@id': `${config.public.PRODUCTION_URL}${localePath({
          name: 'category-slug',
          params: { slug: item.slug },
        }).substr(1)}`,
        url: `${config.public.PRODUCTION_URL}${localePath({
          name: 'category-slug',
          params: { slug: item.slug },
        }).substr(1)}`,
        name: item.name,
      },
    }))
    return {
      '@context': 'http://schema.org',
      '@graph': [
        {
          '@type': 'BreadcrumbList',
          itemListElement: items,
        },
      ],
    }
  }

  const corporationSchema = () => {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Corporation',
          name: 'StockPro',
          url: `${config.public.PRODUCTION_URL}`,
          logo: `${config.public.PRODUCTION_URL}logo.svg`,
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: country.phoneNumber,
            contactType: 'customer service',
          },
          sameAs: [
            'https://www.facebook.com/stockprofrance/',
            'https://linkedin.com/company/stockpro',
            'https://www.youtube.com/channel/UCTvHKa_JcH4Oyn41h7qB7Tg',
            'https://www.stock-pro.fr',
            'https://twitter.com/francestockpro',
            `http://www.${country.host}`,
          ],
        },
      ],
    }
  }

  const blogArticleSchema = () => {
    const blogPost = post => {
      const fullArticle = [
        articleContent.value,
        ...((post?.content?.blocks || [])?.map(x => (x?.text ? renderRichText(x.text) : null)) || []),
      ]
        .filter(x => x)
        ?.join('\n')
      const articleKeywords = post?.relatedTags?.map(tag => tag?.content?.title).join(' ')
      return {
        '@context': 'https://schema.org',
        '@graph': [
          {
            '@type': 'BlogPosting',
            headline: post?.title || post?.content?.title,
            image:
              post?.mainImage?.filename ||
              post?.mainImage?.url ||
              post?.mainImage ||
              post?.content?.mainImage?.filename ||
              post?.content?.mainImage?.url ||
              post?.content?.mainImage,
            editor:
              !post?.author?.length || !post?.content?.author?.length
                ? 'StockPro'
                : post?.author || post?.content?.author,
            genre: post?.category?.content?.name || post?.content?.category?.content?.name,
            publisher: 'StockPro',
            keywords: articleKeywords,
            url: post?.full_slug,
            datePublished: post?.published_at || post?.content?.published_at,
            dateCreated: post?.created_at || post?.content?.created_at,
            dateModified: post?.updatedDate || post?.content?.updatedDate,
            description: post?.shortDescription || post?.content?.shortDescription || fullArticle.substr(0, 200),
            articleBody: fullArticle,
            author: {
              '@type': 'Person',
              name:
                !post?.author?.length || !post?.content?.author?.length
                  ? 'StockPro'
                  : post?.author || post?.content?.author,
            },
          },
        ],
      }
    }
    if (blogHome?.value) {
      return {
        '@context': 'https://schema.org',
        '@graph': [
          {
            '@type': 'Blog',
            name: dynamicMeta.value.title,
            url: `${config.public.PRODUCTION_URL}${route.path.substr(1)}`,
            description: dynamicMeta.value.description,
            publisher: 'StockPro',
            blogPost: articles.value.map(post => blogPost(post)),
          },
        ],
      }
    } else {
      return blogPost(article?.value)
    }
  }

  return {
    defaultHead,
    defaultSeo,
    customKey,
    customTarget,
    customScript,
    totalPages,
    dynamicMeta,
    noIndex,
    paginationHead,
    homeHead,
    productHead,
    categoryHead,
    blogHead,
    references,
    reference,
    category,
    merchant,
    brand,
    article,
    articles,
    articleContent,
    blogHome,
  }
}
