
import * as cloudinaryRuntime$DK1GI6DIn6 from '/app/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as storyblokRuntime$JN4DvOoYXu from '/app/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as cloudfrontRuntime$QxH1NA8mxA from '/app/utils/providers/cloudfront.js'
import * as ipxRuntime$bLXOQnc2jF from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 576,
    "sm": 768,
    "md": 1024,
    "lg": 1440,
    "xl": 1920,
    "xxl": 1920,
    "2xl": 1536,
    "4k": 1921
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$DK1GI6DIn6, defaults: {"baseURL":"https://res.cloudinary.com/hr363qyfe/image/upload/"} },
  ['storyblok']: { provider: storyblokRuntime$JN4DvOoYXu, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['cloudfront']: { provider: cloudfrontRuntime$QxH1NA8mxA, defaults: {} },
  ['ipx']: { provider: ipxRuntime$bLXOQnc2jF, defaults: {} }
}
        