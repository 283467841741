import { defineStore } from 'pinia'

const state = () => ({
  showVAT: true,
  pageData: null,
})

const getters = {
  getConfig: state => state,
  getKey: state => key => state[key],
  getShowVAT: state => state.showVAT,
  getPageData: state => state.pageData,
}

const actions = {
  add(options) {
    Object.assign(this, options)
  },
  remove(keys) {
    keys.forEach(key => {
      delete this[key]
    })
  },
}

export const useConfigStore = defineStore('configStore', {
  state,
  getters,
  actions,
})
