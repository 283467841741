import Repository from './repository'

export default class CartRepository extends Repository {
  async updateStatus(userUid, companyUid, cartUid, status, mapping = this.mapper.basicMapping) {
    try {
      const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
      const config = {
        headers: {
          Mapping: mapping,
        },
      }
      await this.fetcher.put(url, { status }, config)
      return true
    } catch (e) {
      return false
    }
  }

  async get(userUid, companyUid, cartUid, mapping = this.mapper.cartMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.cart)
  }

  async validateCoupon(coupon) {
    const url = `${this.backendUrl}coupons/${coupon}/valid`
    return this.fetcher.get(url).then(response => response)
  }

  async check(userUid, companyUid, cartUid, mapping = this.mapper.cartMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/check`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.cart)
  }

  async delete(userUid, companyUid, cartUid) {
    try {
      const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
      return this.fetcher.delete(url)
      // return true
    } catch (e) {
      console.log('error deleting cart', e)
      return false
    }
  }

  async create({ userUid, companyUid, merchants, coupons, utm }, mapping = this.mapper.cartMapping) {
    let cartUid = null
    try {
      if (merchants.length > 0) {
        const cartBaseUrl = `${this.backendUrl}companies/${companyUid}/users/${userUid}/`
        const url = `${cartBaseUrl}cart/stocks`
        const cartMerchants = []
        const config = {
          headers: {
            Mapping: mapping,
          },
        }
        merchants.forEach(merchant => {
          const stocks = []
          merchant.items.forEach(item => {
            stocks.push({
              stock_uid: item.uid,
              quantity: item.quantity,
              price: item.stock?.price,
            })
          })
          cartMerchants.push({
            stocks,
            delivery_method: merchant.deliveryMethod,
          })
        })
        const cartResponse = await this.fetcher
          .post(url, { merchants: cartMerchants, ...utm }, config)
          .then(response => {
            if (response.success) {
              cartUid = response.cart.uid
              if (coupons.length > 0) {
                const couponUrl = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/coupons`
                const requests = []
                coupons.forEach(coupon => {
                  requests.push(this.fetcher.post(couponUrl, { coupon_code: coupon.code }, config))
                })
                return Promise.all(requests)
              }
              return Promise.resolve([response])
            }
            throw new Error(response.message)
          })
          .then(responses => {
            // Make sure to get the most up to date response
            // by getting the cart response with most used coupons
            const response = responses.sort((a, b) => {
              return b.data.cart.coupons.length - a.data.cart.coupons.length
            })
            if (response[0].success) {
              return response[0].cart
            }
            this.delete(userUid, companyUid, cartUid)
            throw new Error('bad_parameter')
          })
        return cartResponse
      }
    } catch (e) {
      if (cartUid !== null) {
        this.delete(userUid, companyUid, cartUid)
      }
      throw e
    }

    return null
  }

  async resume(resumeUid, mapping = this.mapper.resumeMapping) {
    const url = `${this.backendUrl}/carts/${resumeUid}/resume`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.cart)
  }

  async order(userUid, companyUid, cartUid, payload, mapping = this.mapper.cartMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/order`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, payload, config).then(response => response.cart)
  }

  async quote(userUid, companyUid, cartUid, payload = {}, mapping = this.mapper.cartMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}/quote`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.post(url, payload, config).then(response => response.cart)
  }

  async setAddress(userUid, companyUid, cartUid, deliveryAddressUid, mapping = this.mapper.cartMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher
      .put(url, { cart: { delivery_address_uid: deliveryAddressUid } }, config)
      .then(response => response.cart)
  }

  async getCartLocation(userUid, companyUid, cartUid, location, mapping = this.mapper.cartMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}/carts/${cartUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher
      .get(url, { params: { 'location[lat]': location.lat, 'location[lon]': location.lng } }, config)
      .then(response => response.cart)
  }
}
