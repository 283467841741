<template>
  <div class="sb-additional-services-banner" :style="`background-color: ${bg_color}`">
    <nuxt-link to="/services/additional">
      <div class="sb-additional-services-banner-container">
        <div class="sb-additional-services-banner-container-title">
          {{ $t('proArea.services.discoverAdditionnals') }}

          <div class="icon-arrow-right u-bg-dark-blue hide-md" />
        </div>

        <div class="sb-additional-services-banner-container-lists">
          <div
            v-for="parent in getServicesParentList"
            :key="parent"
            class="sb-additional-services-banner-container-lists-list"
          >
            <div
              v-for="child in getServicesChildList(parent)"
              :key="child"
              class="sb-additional-services-banner-container-lists-list-item"
            >
              <nuxt-link
                v-if="getStoryBySlug(child) && getStoryBySlug(child).content"
                :to="`/services/additional#${getStoryBySlug(child).full_slug?.split('/').pop()}`"
              >
                <ImageLazy
                  v-if="getStoryBySlug(child).content?.logo?.filename"
                  :src="getStoryBySlug(child).content.logo.filename"
                  alt="service logo"
                  class-variant="sb-small-icon"
                />
                <span>{{ getStoryBySlug(child).content.title }}</span>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SbAdditionalServicesBanner',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: null },
  },
  computed: {
    ...mapGetters('proArea', ['getStoryBySlug', 'getServicesParentList', 'getServicesChildList']),
  },
}
</script>

<style lang="scss">
.sb-additional-services-banner {
  padding: $spacing-xl;
  a {
    color: var(--dark-blue);
    text-decoration: none;
  }

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-container {
    background-color: var(--bg-grey);
    border-radius: 5px;
    margin: 0 auto;
    max-width: 1000px;
    padding: $spacing-md;

    &-title {
      font-weight: 800;
      font-size: pxToRem(15px);
      padding-bottom: $spacing-md;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-lists {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 1fr;
      grid-gap: 0 $spacing-sm;
      grid-auto-flow: column;

      @include mq($mq-xs) {
        display: block;
      }

      &-list {
        align-items: center;
        gap: $spacing-sm $spacing-sm;
        display: grid;
        grid-template-columns: repeat(auto-fit, 130px);
        grid-gap: $spacing-sm $spacing-sm;

        &:last-of-type {
          padding-bottom: 0;
        }

        @include mq($mq-xs) {
          display: block;
        }

        &-item {
          display: flex;
          height: 48px;

          a {
            align-items: center;
            color: var(--dark-blue);
            display: flex;
            gap: $spacing-xs;
            text-decoration: none;
          }
          span {
            font-size: pxToRem(12px);
          }
        }
      }
    }
  }
}
</style>
