import CartManager from '@/services/CartManager'
import { useCartStore } from '@/stores/cart'
import { useCurrenti18n } from '@/composables/useCurrenti18n'
import { useI18nStore } from '@/stores/i18nStore'

export default defineNuxtPlugin(() => {
  const i18nStore = useI18nStore()
  const config = useRuntimeConfig()

  let country = null
  if (i18nStore?.country) {
    country = i18nStore.country
  }
  if (!country) {
    const currenti18n = useCurrenti18n()
    country = currenti18n.currentCountry()
    i18nStore.country = country
  }
  const cartStore = useCartStore()
  return {
    provide: {
      cart: () => new CartManager(cartStore, country, config),
    },
  }
})
