import Repository from './repository'

export default class StoryblokRepository extends Repository {
  async get(url, params) {
    if (!params['version']) {
      params['version'] = this.runtimeConfig.public.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
    }
    try {
      if (!params['token']) {
        params['token'] = this.runtimeConfig.public.STORYBLOK_KEY
      }
      if (!params['sort_by'] && !params.uniqueStory) {
        params['sort_by'] =
          'content.order:asc;content.previous_published_at:desc;first_published_at:desc;published_at:desc;created_at:desc'
      } else {
        delete params.uniqueStory
      }
      const response = await this.storyApi.get(`cdn/stories${url}`, params)
      return Promise.resolve(response)
    } catch (e) {
      // if no result, normal storyblok get
      console.log(e)
    }
  }
}
