<template>
  <div
    v-if="subtitle"
    :style="`${bg_color ? `background-color: ${bg_color}` : ''}; ${has_padding_top ? 'padding-top: 20px' : ''}`"
  >
    <div class="inner-container fad">
      <div
        class="sb-subtitle u-c-landing-color-secondary"
        :style="text_color ? `color: ${text_color} !important` : ''"
        :class="{ 'text-center': text_center }"
      >
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbSubtitle',
  props: {
    subtitle: { type: String, default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    text_center: { type: Boolean, default: false },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: null },
    // eslint-disable-next-line vue/prop-name-casing
    text_color: { type: String, default: null },
    // eslint-disable-next-line vue/prop-name-casing
    has_padding_top: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
.sb {
  &-subtitle {
    font-size: pxToRem(15px);
    line-height: 1.33;
    padding-bottom: 20px;
    font-weight: 400;

    @include mq($mq-md) {
      font-weight: 400;
    }

    p {
      // TODO: should not be needed anymore when newcss is removed
      font-size: pxToRem(15px);
      line-height: 1.33;
      padding-bottom: 20px;

      @include mq($mq-md) {
        font-weight: 400;
      }
    }

    strong,
    b {
      font-weight: 800;
    }

    &--no-mb {
      margin-bottom: 0;
    }
  }
}
</style>
