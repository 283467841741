export const state = () => ({
  isXxs: false,
  isXs: false,
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false,
  isMobile: false,
  isTablet: false,
})

export const mutations = {
  setSize(state, { key, value }) {
    state[key] = value
  },
}

export const actions = {
  handleResize({ commit }) {
    commit('setSize', { key: 'isXxs', value: window.innerWidth <= 320 })
    commit('setSize', { key: 'isXs', value: window.innerWidth <= 576 })
    commit('setSize', { key: 'isSm', value: window.innerWidth <= 768 })
    commit('setSize', { key: 'isMd', value: window.innerWidth <= 1024 })
    commit('setSize', { key: 'isLg', value: window.innerWidth <= 1440 })
    commit('setSize', { key: 'isXl', value: window.innerWidth <= 1920 })
    commit('setSize', { key: 'isMobile', value: window.innerWidth < 768 })
    commit('setSize', {
      key: 'isTablet',
      value: window.innerWidth >= 768 && window.innerWidth <= 1440,
    })
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
