<template>
  <div class="sb-services-section">
    <div class="sb-services-section-container">
      <div class="sb-services-section-container-services">
        <div
          v-for="(parent, idx) in getServicesParentList"
          :key="parent"
          class="sb-services-section-container-services-list"
        >
          <div
            v-if="
              getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))) &&
              getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content
            "
            class="sb-services-section-container-services-list-parent"
            :style="`--sbServiceResumeHoverColor: ${getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.main_color}`"
            :is-stock="parent === 'stockpro-folder'"
            @mouseover="hovered[idx] = true"
            @mouseleave="hovered[idx] = false"
          >
            <nuxt-link :to="`/services/${getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).slug}`">
              <div class="sb-services-section-container-services-list-parent-logo">
                <ImageLazy
                  :src="
                    hovered && hovered[idx]
                      ? getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.icon_active.filename
                      : getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.icon.filename
                  "
                  alt="service logo"
                />
              </div>

              <div class="sb-services-section-container-services-list-parent-title">
                {{ getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.title }}
              </div>

              <div
                class="sb-services-section-container-services-list-parent-description"
                v-html="richContent(getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.description)"
              />

              <div class="sb-services-section-container-services-list-parent-link">
                {{ $t('actions.knowMore') }}
              </div>
            </nuxt-link>
          </div>

          <nuxt-link to="/services/additional">
            <div
              class="sb-services-section-container-services-list-child"
              :style="`--sbServiceResumeHoverColor: ${
                getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))) &&
                getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content
                  ? getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.main_color
                  : 'var(--white)'
              }`"
              :is-service-stock="parent === 'stockpro-folder'"
            >
              <div class="sb-services-section-container-services-list-child-description">
                <span>{{ $t('proArea.services.findComplementary') }}</span>
              </div>

              <div class="sb-services-section-container-services-list-child-list">
                <div
                  v-for="child in getServicesChildList(parent)"
                  :key="child"
                  class="sb-services-section-container-services-list-child-list-item"
                >
                  <ImageLazy
                    v-if="getStoryBySlug(child).content && getStoryBySlug(child).content.logo"
                    :src="getStoryBySlug(child).content.logo.filename"
                    alt="service logo"
                  />
                </div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SbServicesResumeSection',
  data() {
    return {
      hovered: [],
    }
  },
  computed: {
    ...mapGetters('proArea', ['getStoryBySlug', 'getServicesParentList', 'getServicesChildList']),
  },
  methods: {
    richContent(content) {
      if (content) {
        return renderRichText(content)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.sb-services-section {
  padding: $spacing-xl;
  background-color: var(--bg-grey);

  @include mq($mq-sm) {
    padding: $spacing-md;
  }

  &-container {
    max-width: 1000px;
    margin: 0 auto;

    &-title {
      text-align: center;
      font-weight: 800;
      font-size: pxToRem(30px);
      padding-bottom: $spacing-xl;
    }

    &-subtitle {
      font-weight: 400;
      font-size: pxToRem(15px);
    }

    &-services {
      display: flex;
      flex-direction: row;
      gap: $spacing-md;
      justify-content: center;

      a {
        text-decoration: none;
      }

      @include mq($mq-sm) {
        flex-direction: column;
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;

        &-parent,
        &-child {
          width: 490px;
          background-color: var(--white);
          border-radius: 5px;
          padding: $spacing-md;

          @include mq($mq-md) {
            width: 322px;
          }

          @include mq($mq-sm) {
            width: 100%;
          }
        }

        &-parent {
          a {
            text-decoration: none;
          }

          &:hover,
          &:hover &-title,
          &:hover &-description p,
          &:hover &-link {
            background-color: var(--sbServiceResumeHoverColor);
            color: var(--dark-blue);
          }

          &[is-stock='true']:hover,
          &[is-stock='true']:hover &-title,
          &[is-stock='true']:hover &-description p,
          &[is-stock='true']:hover &-link {
            color: var(--white) !important;
          }

          &-logo {
            width: 48px;
            height: 48px;
            margin-bottom: $spacing-sm;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &-title {
            font-weight: 800;
            font-size: pxToRem(15px);
            padding-bottom: $spacing-sm;
            color: var(--dark-blue);
          }

          &-description,
          &-description p {
            font-weight: 400;
            font-size: pxToRem(15px);
            color: var(--steel-grey);
            padding-bottom: $spacing-sm;
          }

          &-link {
            font-weight: 800;
            font-size: pxToRem(15px);
            color: var(--primary);
            text-decoration: underline;
          }
        }

        &-child {
          &:hover,
          &:hover &-description {
            background-color: var(--sbServiceResumeHoverColor);
            color: var(--dark-blue);
            img {
              filter: brightness(0);
            }
          }
          &[is-service-stock='true']:hover,
          &[is-service-stock='true']:hover &-description {
            color: var(--white) !important;

            img {
              filter: brightness(0) invert(1);
            }
          }

          &-description {
            font-weight: 400;
            font-size: pxToRem(15px);
            color: var(--steel-grey);
            padding-bottom: $spacing-md;
          }

          &-list {
            display: flex;
            gap: $spacing-md;
            align-items: center;
            flex-wrap: wrap;

            &-item {
              width: 28px;
              cursor: pointer;

              img {
                width: 28px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
