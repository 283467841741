<template>
  <ClientOnly>
    <component
      :is="click ? 'div' : 'NuxtLink'"
      v-if="link.url"
      :to="link.url"
      class="sb-buttons-link-hover"
      :class="{ open, click }"
      @click="toggleButton()"
    >
      <div class="sb-buttons-link-hover-img" :class="{ 'img-large': link.largeIcon }">
        <ImageLazy v-if="link.icon" :src="link.icon" :class-variant="{ 'hide-sm': link.iconSmall }" />
        <ImageLazy v-if="link.iconSmall" :src="link.iconSmall" :class-variant="{ 'show-sm': link.iconSmall }" />
      </div>
      <span v-if="link.labelVisible" class="sb-buttons-link-hover-label">{{ link.label }}</span>
      <div class="sb-buttons-link-hover-link" :class="{ 'hide-sm': !link.labelVisible }">
        <span>{{ link.label }}</span>
        <div class="sb-buttons-link-hover-link-a">
          <NuxtLink :to="link.url">
            <span>{{ $t('actions.knowMore') }}</span>
          </NuxtLink>
        </div>
      </div>
    </component>
  </ClientOnly>
</template>

<script>
import Mobile from '@/mixins/mobile-mixin'
export default {
  name: 'SbButtonsLinkHover',
  mixins: [Mobile],
  props: {
    link: { type: Object, default: () => {} },
    click: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggleButton() {
      if (this.click && !this.isSm) {
        this.open = !this.open
      }
    },
  },
}
</script>

<style lang="scss">
.sb-buttons-link-hover {
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;

  @include mq($mq-sm) {
    background-color: transparent;
  }

  &-img {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    overflow: hidden;
    &:not(.img-large) {
      @include mq($mq-sm) {
        width: 28px !important;
        height: 28px !important;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-link {
    font-weight: 800;
    font-size: pxToRem(15px);
    color: var(--dark-blue);
    white-space: nowrap;
    max-width: 0;
    padding: 0;
    transition: all 0.5s ease;

    span {
      display: none;
    }

    &-a {
      span {
        text-decoration: underline !important;
        font-size: pxToRem(12px) !important;
        color: var(--primary);
      }
    }
  }
  &-label {
    padding-left: $spacing-sm;
    color: var(--dark-blue);
  }
  &.open {
    background-color: var(--bg-grey);
    .sb-buttons-link-hover-link {
      padding: 0 $spacing-sm;
      max-width: 100%;

      span {
        display: block;
      }
    }
    .sb-buttons-link-hover-label {
      display: none;
    }
  }
  &:not(.click):hover {
    background-color: var(--bg-grey);
    .sb-buttons-link-hover-link {
      padding: 0 $spacing-sm;
      max-width: 100%;

      span {
        display: block;
      }
    }
    .sb-buttons-link-hover-label {
      display: none;
    }
  }
}
</style>
