export default class LocalCartProvider {
  constructor(store) {
    this.cartStore = store
  }

  addItem(item) {
    this.cartStore.addItem(item)
  }

  updateItem(item, index) {
    this.cartStore.updateItem({ item, index })
  }

  removeItem(item) {
    this.cartStore.remove(item)
  }

  setDeliveryMethod(delivery) {
    this.cartStore.setDeliveryMethod(delivery)
  }

  setAddress(address) {
    this.cartStore.setAddress(address)
  }

  removeDeliveryMethod(merchantUid) {
    this.cartStore.removeDeliveryMethod(merchantUid)
  }

  addCoupon(coupon) {
    this.cartStore.addCoupon(coupon)
  }

  setIsReady(isReady) {
    this.cartStore.setIsReady(isReady)
  }

  clearCoupons() {
    this.cartStore.clearCoupons()
  }

  clearCart() {
    this.cartStore.clearCart()
  }

  clearRemoteCart() {
    this.cartStore.clearRemoteCart()
  }

  setCartUid(uid) {
    this.cartStore.setCartUid(uid)
  }

  setRemoteCart(cart) {
    this.cartStore.setRemoteCart(cart)
  }

  setVersion(version) {
    this.cartStore.setVersion(version)
  }

  get items() {
    return this.cartStore.getItems
  }

  get deliveryMethods() {
    return this.cartStore.getDeliveryMethods
  }

  get coupons() {
    return this.cartStore.getCoupons
  }

  get cartUid() {
    return this.cartStore.getCartUid
  }

  get remoteCart() {
    return this.cartStore.remoteCart
  }

  get isReady() {
    return this.cartStore.getIsReady
  }

  get version() {
    return this.cartStore.getVersion
  }
}
