import Repository from './repository'

export default class BrandRepository extends Repository {
  async getBrands(filters = [], mapping = this.mapper.brandMapping) {
    const url = `${this.backendUrl}brands`
    const config = {
      params: {
        with_mkp_products: true,
        order_by: 'mkp_products',
      },
      headers: {
        Mapping: mapping,
      },
    }

    if (filters && filters.length > 0) {
      const categoriesFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'CATEGORY') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (categoriesFilter.length > 0) {
        config.params.category_uid = categoriesFilter.join()
      }

      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params.product_query = keywordsFilter.value

      const merchantsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'MERCHANT') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (merchantsFilter.length > 0) {
        config.params.merchant_uids = merchantsFilter.join()
      }

      // const localityFilter = filters.find(f => f.type === 'LOCALITY')
      // let coordinatesFilter = null
      // if (localityFilter) {
      //   coordinatesFilter = localityFilter.value?.split('+')[1]?.split(',')
      //   if (coordinatesFilter) {
      //     config.params.location = {}
      //     ;[config.params.location['lat'], config.params.location['lon']] = coordinatesFilter
      //   }
      // }

      const deliveryFilter = filters.find(f => f.type === 'DELIVERY')
      if (deliveryFilter) config.params.has_delivery = deliveryFilter.value

      const minPriceFilter = filters.find(f => f.type === 'MIN_PRICE')
      if (minPriceFilter) config.params.min_price = minPriceFilter.value

      const maxPriceFilter = filters.find(f => f.type === 'MAX_PRICE')
      if (maxPriceFilter) config.params.max_price = maxPriceFilter.value

      let freshnessFilter = filters.filter(f => f.type === 'FRESHNESS')
      if (freshnessFilter.length > 0) config.params.mkp_freshness = Math.max(...freshnessFilter.map(f => f.value))

      let underBestDealsFilter = filters.filter(f => f.type === 'BESTDEALS' && f.query === 'under_best_deal')
      if (underBestDealsFilter.length > 0)
        config.params.under_best_deal = Math.min(...underBestDealsFilter.map(f => f.value?.split('_')[0]))

      let bestDealsFilter = filters.filter(f => f.type === 'BESTDEALS' && f.query === 'best_deal')
      if (bestDealsFilter.length > 0) {
        config.params.best_deal = Math.min(...bestDealsFilter.map(f => f.value?.split('_')[0]))
      }
    }

    return this.fetcher.get(url, config).then(response => response.brands)
  }

  async getBrandBySlug(slug, mapping = this.mapper.brandMapping) {
    const url = `${this.backendUrl}brands/${slug}`
    const config = {
      use_native: true,
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.brand)
  }

  // Get brands with with_mkp_products=false
  async getWebstockBrands(mapping = this.mapper.brandMapping) {
    const url = `${this.backendUrl}brands`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.fetcher.get(url, config).then(response => response.brands)
  }
}
