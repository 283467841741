import Repository from './repository'

export default class AppPurchase extends Repository {
  async get() {
    const url = `${this.backendUrl}app_purchases`
    return this.fetcher.get(url).then(response => {
      return response.app_purchases
    })
  }
}
