<template>
  <div :key="$route.fullPath">
    <div v-if="rawProArea && rawProArea.content && rawProArea.content.body" class="landing-download u-bg-bg-grey">
      <SbContent :content="rawProArea.content" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Scrollto from '@/mixins/scrollto-mixin'
import SbContent from '@/components/Storyblok/SbContent'

export default {
  name: 'SbProContent',
  components: {
    SbContent,
  },
  mixins: [Scrollto],
  props: {
    slug: { type: String, default: 'home' },
  },
  computed: {
    ...mapGetters('proArea', ['getStoryBySlug']),
    rawProArea() {
      return this.getStoryBySlug(this.slug)
    },
  },
}
</script>

<style lang="scss">
.sb-icon {
  width: 38px;
  height: 38px;
  img {
    width: 38px;
  }
}
.sb-small-icon {
  width: 28px;
  height: 28px;
  img {
    width: 28px;
  }
}
</style>
