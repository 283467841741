import ApiService from '@/services/ApiService'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const backendUrl = runtimeConfig.public.BACKEND_URL
  const storyblokApi = useStoryblokApi()

  return {
    provide: {
      api: () => {
        return new ApiService({
          backendUrl,
          storyApi: storyblokApi,
          runtimeConfig,
        })
      },
    },
  }
})
