<template>
  <div class="sb-avatar-list-wrapper inner-container fad" :style="`--avatarBgColor: ${bg_color}`">
    <div v-if="avatar" class="sb-avatar-list">
      <SbAvatar v-for="a in avatar" :key="a._uid" :avatar="a" class="sb-avatar-list-item" />
    </div>
  </div>
</template>

<script>
import SbAvatar from '@/components/Storyblok/SbAvatar'

export default {
  name: 'SbAvatarList',
  components: {
    SbAvatar,
  },
  props: {
    avatar: { type: [Object, Array], default: undefined },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: '' },
  },
}
</script>

<style lang="scss">
.sb-avatar-list-wrapper {
  background-color: var(--avatarBgColor);
  width: 100%;
  @include mq($mq-xs) {
    padding: 0 $spacing-md;
  }
}
.sb-avatar-list {
  display: grid;
  gap: $spacing-xl;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: $spacing-xl;
  @include mq($mq-md) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq($mq-sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  &-item {
    width: 220px !important;
    @include mq($mq-xs) {
      width: 100% !important;
    }
    .full-width {
      flex: 1;
    }
  }
}
</style>
