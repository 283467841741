<template>
  <div :key="$route.fullPath">
    <template v-for="section in content?.body" :key="section._uid">
      <component
        :is="section.component"
        :element-test="elementTest"
        class="landing-download__section"
        v-bind="section"
      />
    </template>
  </div>
</template>

<script>
import LdTitle from '@/components/LandingDownload/LdTitle'
import LdSubtitle from '@/components/LandingDownload/LdSubtitle'
import LdFeaturesSection from '@/components/LandingDownload/LdFeaturesSection'
import LdDownloadBanner from '@/components/LandingDownload/LdDownloadBanner'
import LdNetworkSection from '@/components/LandingDownload/LdNetworkSection'
import LdDemoBanner from '@/components/LandingDownload/LdDemoBanner'
import LdContactForm from '@/components/LandingDownload/LdContactForm'
import LdVideoSection from '@/components/LandingDownload/LdVideoSection'
import LdPricingPlan from '@/components/LandingDownload/LdPricingPlan'
import LdAgecSection from '@/components/LandingDownload/LdAgecSection'
import LdContainerBanner from '@/components/LandingDownload/LdContainerBanner'
import LdFullwidthBanner from '@/components/LandingDownload/LdFullwidthBanner'
import SbAdditionalServicesBanner from '@/components/Storyblok/SbAdditionalServicesBanner'
import SbAdditionalServicesPage from '@/components/Storyblok/SbAdditionalServicesPage'
import SbAdditionalServicesSection from '@/components/Storyblok/SbAdditionalServicesSection'
import SbAgecSection from '@/components/Storyblok/SbAgecSection'
import SbAvatar from '@/components/Storyblok/SbAvatar'
import SbAvatarList from '@/components/Storyblok/SbAvatarList'
import SbBlogAd from '@/components/Storyblok/SbBlogAd'
import SbButtonsLinkHover from '@/components/Storyblok/SbButtonsLinkHover'
import SbContactForm from '@/components/Storyblok/SbContactForm'
import SbContainerBanner from '@/components/Storyblok/SbContainerBanner'
import SbCta from '@/components/Storyblok/SbCta'
import SbCtaBanner from '@/components/Storyblok/SbCtaBanner'
import SbCtaGroup from '@/components/Storyblok/SbCtaGroup'
import SbDemoBanner from '@/components/Storyblok/SbDemoBanner'
import SbDownloadBanner from '@/components/Storyblok/SbDownloadBanner'
import SbFeatureBlock from '@/components/Storyblok/SbFeatureBlock'
import SbFeaturesSection from '@/components/Storyblok/SbFeaturesSection'
import SbFullwidthBanner from '@/components/Storyblok/SbFullwidthBanner'
import SbNetworkSection from '@/components/Storyblok/SbNetworkSection'
import SbNewsCard from '@/components/Storyblok/SbNewsCard'
import SbOfferDetail from '@/components/Storyblok/SbOfferDetail'
import SbOffersSection from '@/components/Storyblok/SbOffersSection'
import SbOffersSelectionGrid from '@/components/Storyblok/SbOffersSelectionGrid'
import SbPlan from '@/components/Storyblok/SbPlan'
import SbPlansGroup from '@/components/Storyblok/SbPlansGroup'
import SbPricingPlan from '@/components/Storyblok/SbPricingPlan'
import SbRichText from '@/components/Storyblok/SbRichText'
import SbSeoContent from '@/components/Storyblok/SbSeoContent'
import SbServicesResumeSection from '@/components/Storyblok/SbServicesResumeSection'
import SbServicesSection from '@/components/Storyblok/SbServicesSection'
import SbSubscribeModal from '@/components/Storyblok/SbSubscribeModal'
import SbSubtitle from '@/components/Storyblok/SbSubtitle'
import SbTestimoniesTabs from '@/components/Storyblok/SbTestimoniesTabs'
import SbTitle from '@/components/Storyblok/SbTitle'
import SbVideoSection from '@/components/Storyblok/SbVideoSection'
import SbWhiteBookForm from '@/components/Storyblok/SbWhiteBookForm'
import SbTable from '@/components/Storyblok/SbTable'
import Slider from '@/components/Slider/Slider'
import ContactForm from '@/components/Contact/ContactForm'

export default {
  name: 'SbContent',
  components: {
    LdTitle,
    LdSubtitle,
    LdFeaturesSection,
    LdDownloadBanner,
    LdNetworkSection,
    LdDemoBanner,
    LdContactForm,
    LdVideoSection,
    LdPricingPlan,
    LdAgecSection,
    LdContainerBanner,
    LdFullwidthBanner,
    SbAdditionalServicesBanner,
    SbAdditionalServicesPage,
    SbAdditionalServicesSection,
    SbAgecSection,
    SbAvatar,
    SbAvatarList,
    SbBlogAd,
    SbButtonsLinkHover,
    SbContactForm,
    SbContainerBanner,
    SbCta,
    SbCtaBanner,
    SbCtaGroup,
    SbDemoBanner,
    SbDownloadBanner,
    SbFeatureBlock,
    SbFeaturesSection,
    SbFullwidthBanner,
    SbNetworkSection,
    SbNewsCard,
    SbOfferDetail,
    SbOffersSection,
    SbOffersSelectionGrid,
    SbPlan,
    SbPlansGroup,
    SbPricingPlan,
    SbRichText,
    SbSeoContent,
    SbServicesResumeSection,
    SbServicesSection,
    SbSubscribeModal,
    SbSubtitle,
    SbTestimoniesTabs,
    SbTitle,
    SbVideoSection,
    SbWhiteBookForm,
    SbTable,
    Slider,
    ContactForm,
  },
  props: {
    content: { type: Object, default: () => {} },
    imgcls: { type: Boolean, default: false },
    elementTest: { type: String, default: '' },
  },
  data() {
    return {
      cls: this.imgcls,
    }
  },
}
</script>
