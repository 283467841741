import Repository from './repository'
function getFilters(filters, config) {
  const startDateFilter = filters.find(filter => filter.type === 'START_DATE')
  if (startDateFilter) config.params.start_date = startDateFilter.value

  const endDateFilter = filters.find(filter => filter.type === 'END_DATE')
  if (endDateFilter) config.params.end_date = endDateFilter.value

  const channelFilter = filters.reduce((filtered, filter) => {
    if (filter.type === 'CHANNEL') {
      filtered.push(filter.value)
    }
    return filtered
  }, [])
  if (channelFilter.length > 0) {
    config.params.channel = channelFilter.join()
  }

  const companyFilter = filters.reduce((filtered, filter) => {
    if (filter.type === 'COMPANY') {
      filtered.push(filter.value)
    }
    return filtered
  }, [])
  if (companyFilter.length > 0) {
    config.params['company_uids[]'] = companyFilter.join()
  }
  return config
}

export default class DashboardRepository extends Repository {
  async getSales(filters = []) {
    const url = `${this.backendUrl}customer_dashboard/sales`
    let config = {
      params: {},
    }
    if (filters && filters.length > 0) {
      let computedFilters = getFilters(filters, config)
      config = { ...config, ...computedFilters }
    }
    return this.fetcher.get(url, config).then(response => response)
  }

  async getCustomers(filters = []) {
    const url = `${this.backendUrl}customer_dashboard/customers`
    let config = {
      params: {},
    }
    if (filters && filters.length > 0) {
      let computedFilters = getFilters(filters, config)
      config = { ...config, ...computedFilters }
    }
    return this.fetcher.get(url, config).then(response => response)
  }

  async getStocks(filters = []) {
    const url = `${this.backendUrl}customer_dashboard/stocks`
    let config = {
      params: {},
    }
    if (filters && filters.length > 0) {
      let computedFilters = getFilters(filters, config)
      config = { ...config, ...computedFilters }
    }
    return this.fetcher.get(url, config).then(response => response)
  }
}
