import { defineStore } from 'pinia'

const state = () => ({
  user: null,
  zipCode: null,
  location: {
    lat: null,
    lng: null,
  },
})

const getters = {
  getUser: state => state.user,
  getZipCode: state => state.zipCode,
  getLocation: state => state.location,
}

const actions = {
  setUser(user) {
    this.user = user
    if (!this.zipCode && this.user?.addresses?.[0]?.zip_code) {
      this.setZipCode(this.user.addresses[0].zip_code)
    }
    if (!this.zipCode && this.user?.company?.addresses?.[0]?.zip_code) {
      this.setZipCode(this.user.company.addresses[0].zip_code)
    }
  },
  setZipCode(zipCode) {
    this.zipCode = zipCode
  },
  setLocation(location) {
    this.location = location
    localStorage.setItem('x-user-location', JSON.stringify(location))
  },
}

export const useUserStore = defineStore('userStore', {
  state,
  getters,
  actions,
})
