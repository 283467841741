import { useClubStore } from '@/stores/club'
import { useI18nStore } from '@/stores/i18nStore'
import { useConfigStore } from '@/stores/config'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user'

export default class UserService {
  constructor(store, user) {
    const userStore = useUserStore()
    this.userStore = userStore
    this.store = store
    const i18nStore = useI18nStore()
    this.country = i18nStore.country
    this._user = user
  }

  async login(token, user = {}) {
    this.setLocalToken(null)
    const { setToken } = useAuthState()
    if (token.includes('Bearer')) {
      token = token.split('Bearer ')[1]
    }
    setToken(token)
    const { getSession } = useAuth()
    await getSession()
    const { $api } = useNuxtApp()
    const apiUser = await $api().user.getMe()
    this.setUser(Object.assign(user, apiUser))
    this.setLocalToken(token)
    this.store.commit('activation/setPhone', null)
    this.store.commit('activation/setEmailOrPhone', null)
    this.store.commit('activation/setForgotPassword', false)

    const cartStore = useCartStore()
    if (this.user?.cart_uid !== null && this.user?.cart_uid !== undefined) {
      cartStore.setCartUid(this.user?.cart_uid)
    } else {
      cartStore.setCartUid(null)
    }

    const configStore = useConfigStore()
    if (this.isPro) {
      configStore.add({ showVAT: false })
    } else {
      configStore.add({ showVAT: true })
    }

    const clubStore = useClubStore()
    if (clubStore.isClub) {
      clubStore.getClub()
    }
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.setLocalToken(null)
      try {
        const { clearToken } = useAuthState()
        clearToken()
        this.clearUser()
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  setLocalToken(token) {
    if (token) {
      const authCookie = useCookie('auth.token', { maxAge: 60 * 60 * 24 * 6, domain: `.${this.country?.host}` })
      authCookie.value = token
    } else {
      const authCookie = useCookie('auth.token', { maxAge: 60 * 60 * 24 * 6, domain: `.${this.country?.host}` })
      authCookie.value = null
    }
  }

  setUser(user) {
    this._user = user
    this.userStore.setUser(user)
  }

  clearUser() {
    this.setUser(null)
    reloadNuxtApp()
  }

  get user() {
    const { data } = useAuth()
    return this.userStore?.user || this._user || data?.value?.user
  }

  get name() {
    return this.user?.first_name
  }

  get lastName() {
    return this.user?.last_name
  }

  get fullName() {
    return this.user ? `${this.user?.first_name || ''} ${this.user?.last_name || ''}` : null
  }

  get uid() {
    return this.user?.uid
  }

  get email() {
    return this.user?.email
  }

  get createdAt() {
    return this.user?.created_at
  }

  get company() {
    return this.user?.company
  }

  get companyUid() {
    return this.user?.company?.uid
  }

  get companyName() {
    return this.user?.company?.name
  }

  get phone() {
    return this.user?.phone_number
  }

  get token() {
    const { token } = useAuthState()
    return token?.value
  }

  get isFreemium() {
    return this.user?.company?.status === 'free'
  }

  get hasCompanyOptionsDashboard() {
    return this.user?.company?.options?.includes('customer_dashboard')
  }

  get isValidUser() {
    return this.user !== null && this.user !== undefined
  }

  get roleName() {
    return this.user?.role?.name
  }

  get isPro() {
    return this.isValidUser && this.user?.role?.name !== 'Individual'
  }

  get isEmployee() {
    return this.isValidUser && this.user?.role?.name === 'Employee'
  }

  get isOwner() {
    return this.isValidUser && this.user?.role?.name === 'Owner'
  }

  get isAdmin() {
    return this.isValidUser && this.user?.role?.name === 'Admin'
  }

  get isAuthenticated() {
    const { status } = useAuth()
    return status?.value === 'authenticated' && this.isValidUser
  }

  get clubs() {
    // Filter out other group kind like GROUP
    return this.isValidUser && this.user?.company_groups?.filter(g => g.kind == 'MKP_CLUB' && g.key !== 'stockpro')
  }

  get isSalesRep() {
    return this.user?.user_roles?.find(role => role?.company?.uid === this.user?.company?.uid)?.is_sales_rep || false
  }

  get isPriceTeam() {
    return this.user?.user_roles?.find(role => role?.company?.uid === this.user?.company?.uid)?.is_price_team || false
  }

  get isSalesAdmin() {
    return this.user?.user_roles?.find(role => role?.company?.uid === this.user?.company?.uid)?.is_sales_admin || false
  }
}
