import { defineStore } from 'pinia'

export const useMerchants = defineStore('merchants', {
  state: () => ({
    all: [],
  }),
  getters: {
    merchantByUid: state => uid => state.all.find(m => m.uid === uid),
    merchantBySlug: state => slug => state.all.find(m => m.slug === slug),
  },
  actions: {
    addMerchants(merchants) {
      let allMerchants = this.all.concat(merchants)
      allMerchants = allMerchants.filter(
        (m, index, self) => index === self.findIndex(merchant => m.uid === merchant.uid)
      )
      this.all = allMerchants
    },
  },
})
