// import { activationStore } from '@/store/activation'
// import { brandsStore } from '@/store/brands'
// import { cartStore } from '@/store/cart'
// import { categoriesStore } from '@/store/categories'

export default defineNuxtPlugin(() => {
  // nuxtApp.vueApp.use(activationStore)
  // nuxtApp.vueApp.use(brandsStore)
  // nuxtApp.vueApp.use(cartStore)
  // nuxtApp.vueApp.use(categoriesStore)
})
